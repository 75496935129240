@import "../styles/variables.scss";

.cust-label-group {
    &.no-label {
        margin-top: 12px;
    }

    .label-wrap {
        svg {
            fill: $grey-light;
            cursor: pointer;
            margin-left: 10px;

            &:hover {
                fill: $blue-primary;
            }

        }

        span {
            &.mandatory {
                color: $red;
            }
        }

        label {
            &:empty {
                display: none
            }
        }
    }

    .cutom-select {
        @include b-radius;
        border: 1px solid $grey-border;
        color: $grey-primary;
        padding: 13px;

        input {
            @include common-font;
            margin-left: 0;
        }

        &:focus {
            border-color: $blue;
            box-shadow: inherit;
        }

        &:focus-within {
            box-shadow: none;
        }

        &::-webkit-input-placeholder {
            color: $grey-light;
        }

        &:-ms-input-placeholder {
            color: $grey-light;
        }

        &::placeholder {
            color: $grey-light;
        }

        &.error {
            border-color: $red;
        }

        &.active {
            border-color: $blue;
        }

        .react-dropdown-select-dropdown-handle {
            display: flex;
            background: url(../assets/UpArow.svg) center center no-repeat !important;
            width: 24px;
            height: 24px;

            svg {
                display: none;
            }
        }

        .react-dropdown-select-dropdown {
            @include b-radius;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24);
            padding: 12px 0;
            z-index: 99;

            span {
                @include common-font;
                padding: 10px 20px;
                color: $grey-light;
                min-height: 42px;

                &:hover {
                    background: none;
                    color: $grey-primary;
                }

                &.react-dropdown-select-item-selected {
                    background: url(../assets/SelectTick.svg) right center no-repeat #F5F5F5;
                    color: $grey-light;
                    background-position: center right -10px;
                    padding-right: 30px;
                }
            }
        }
    }
}

.content-nav-sub {

    .basic-info,
    .pricing {
        .cust-label-group {
            a {
                @include font-16;
                font-weight: $semi-bold;
                text-transform: uppercase;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .select-border-none {
        &.max-w-150 {
            .cutom-select {
                .react-dropdown-select-content {
                    span {
                        max-width: 150px;
                    }
                }
            }
        }

        .form-group {
            margin-bottom: 0;

            .no-label {
                margin-top: 0;

                .label-wrap {
                    label {
                        &:empty {
                            display: none;
                        }
                    }
                }

                &.status-dropdown {
                    display: inline-block;

                    .react-dropdown-select-dropdown-handle {
                        display: none;
                    }

                    .react-dropdown-select {
                        min-height: auto;
                        color: #42B72A;
                        background: #E5FFF2 !important;
                        padding: 12px 22px;
                        @include modal-radius;
                        height: 40px;

                        .react-dropdown-select-input {
                            display: none;
                        }

                        .react-dropdown-select-dropdown {
                            min-width: 195px;
                            border: 0;

                            .react-dropdown-select-item {
                                &::before {
                                    content: 'Mark as '
                                }
                            }
                        }
                    }
                }

                &.grey-dropdown {
                    display: inline-block;

                    .react-dropdown-select {
                        background: #fafafa;
                        color: $grey-light;
                        padding: 12px 15px;
                        @include modal-radius;
                        height: 40px;

                        .react-dropdown-select-dropdown {
                            min-width: 195px;
                            border: 0;
                        }

                        span {
                            white-space: nowrap;
                        }

                        input {
                            display: none;
                        }
                    }
                }
            }

            .cutom-select {
                padding: 0;
                height: auto;
                border: 0;
                color: $blue;

                .react-dropdown-select-dropdown {
                    padding: 0;

                    span {
                        @include font-14;
                    }
                }

                input {
                    display: none;
                }

                span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .min-w-drop {
                .cutom-select {
                    color: $grey-light;
                }

                .react-dropdown-select-dropdown {
                    min-width: 195px;
                    border: 0;
                }
            }
        }

        &.onboardstatus-dropdown {
            display: inline-block;

            .react-dropdown-select {
                min-height: auto;
                color: $blue !important;
                background: $blue-socondary !important;
                padding: 12px 22px;
                @include modal-radius;
                height: 40px;

                .react-dropdown-select-input {
                    display: none;
                }

                .react-dropdown-select-dropdown {
                    min-width: 195px;
                    border: 0;
                }
            }
        }
    }

    .dash-details {
        .chart-header {
            .react-dropdown-select-dropdown {
                span {
                    position: relative;

                    &::before {
                        content: '';
                        display: block;
                        width: 14px;
                        height: 14px;

                        position: absolute;
                        left: 15px;
                        top: 15px;
                        border-radius: 3px;
                    }

                    &.react-dropdown-select-item {
                        padding-left: 40px;
                    }

                    &.react-dropdown-select-item-selected {
                        background-image: none;
                    }

                    &:nth-child(1) {
                        &::before {
                            background-color: #489CFF;
                        }
                    }

                    &:nth-child(2) {
                        &::before {
                            background-color: #4296C1;
                        }
                    }

                    &:nth-child(3) {
                        &::before {
                            background-color: #11399F;
                        }
                    }
                }
            }
        }
    }
}