@import './variables.scss';

body {
  font-family: $primary-font;
  background: $grey-bg;
  color: $grey-primary;
  @include common-font();
}

h2 {
  font-size: 24px;
  color: $grey-primary;
  margin-bottom: 0;
  font-weight: $semi-bold;
}

h4 {
  @include h4-font;
  color: $grey-light;
}
h5 {
  font-size: 18px;
  color: $grey-primary;
  font-weight: $semi-bold;
  margin-bottom: 25px;
}
a {
  color: $blue;
  text-decoration: none;

  svg {
    margin-right: 10px;
  }

  &:hover {
    color: $btn-prm-hover;
  }

  &:focus-visible {
    outline: none;
  }

  &.red-link {
    color: $red !important;
    text-decoration: underline;
  }
  &.icon-link {
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
}

hr {
  background-color: $grey-border;
}

h6 {
  @include font-16;
  color: $blue-primary;
  font-weight: $semi-bold;
  margin-bottom: 25px;
}
.gray-text {
  color: $grey-primary;
}
.fw-600 {
  font-weight: 600;
}
//Common Forms
.form-group {
  margin-bottom: 24px;

  label {
    font-weight: $semi-bold;
  }
}

.form-control {
  @include b-radius;
  @include common-font;
  border: 1px solid $grey-border;
  color: $grey-primary;
  height: 52px;

  &:focus,
  &:hover,
  &:active {
    border-color: $blue;
    box-shadow: inherit;
  }

  &::-webkit-input-placeholder {
    color: $grey-light;
  }

  &:-ms-input-placeholder {
    color: $grey-light;
  }

  &::placeholder {
    color: $grey-light;
  }

  &:disabled {
    background: #f4f4f4;
    border: 1px solid #e0e0e0;
    color: rgba($color: #63707e, $alpha: 0.6);

    &:hover {
      border-color: #e0e0e0;
    }
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

//Button Styles
.btn {
  @include btn-radius;
  @include common-font;
  height: 52px;
  padding: 0 35px;
  &:focus,
  &:active,
  &:focus {
    box-shadow: none !important;
  }

  &.min-okw-200 {
    @media (min-width: 1450px) {
      min-width: 200px;
    }
  }

  svg {
    margin-top: -3px;
    margin-right: 12px;

    path {
      fill: $white;
    }
  }

  &.btn-primary {
    background: $blue-btn;
    font-weight: $semi-bold;
    border: none;
    line-height: 26px;

    &:hover,
    &:active,
    &:focus {
      background: $btn-prm-hover !important;
      box-shadow: none !important;
    }
  }

  &.btn-outline-primary {
    border: 2px solid $blue-btn;
    color: $blue;
    font-weight: $semi-bold;
    line-height: 26px;

    &:hover,
    &:active,
    &:focus {
      background: $grey-bg !important;
      box-shadow: none !important;
    }

    svg {
      path {
        fill: $blue-btn;
      }
    }
  }

  &.btn-secondary {
    background: $grey-light;
    font-weight: $semi-bold;
    border: none;
    line-height: 26px;

    &:hover,
    &:active,
    &:focus {
      background: $grey-dark !important;
      box-shadow: none !important;
    }
  }

  &.btn-green {
    background: $green;
    font-weight: $semi-bold;
    border: none;
    line-height: 26px;

    &:hover,
    &:active,
    &:focus {
      background: $dark-green !important;
      box-shadow: none !important;
    }
  }
  &.btn-red {
    background: $red;
    font-weight: $semi-bold;
    border: none;
    line-height: 26px;

    &:hover,
    &:active {
      background: $dark-Red !important;
      box-shadow: none !important;
    }
    &:focus{
      background: $red !important;
    }
  }

  &.btn-danger {
    background: $dark-Red;
    font-weight: $semi-bold;
    border-color: $dark-Red;
    line-height: 26px;
    border-width: 2px;

    &:hover,
    &:active,
    &:focus {
      background: $dark-Red-Hover !important;
    }
  }

  &.btn-outline-secondary {
    color: $grey-light;
    font-weight: $semi-bold;
    border-color: $grey-light;
    line-height: 26px;
    border-width: 2px;

    svg {
      path {
        fill: $grey-light;
      }
    }

    &:hover,
    &:active,
    &:focus {
      background: $grey-light !important;
      color: $white;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  &.btn-outline-success {
    color: $green;
    font-weight: $semi-bold;
    border-color: $green;
    line-height: 26px;
    border-width: 2px;

    &:hover,
    &:active,
    &:focus {
      background: $green !important;
      color: $white;
    }
  }

  &.btn-outline-danger {
    color: $dark-Red;
    font-weight: $semi-bold;
    border-color: $dark-Red;
    line-height: 26px;
    border-width: 2px;

    &:hover,
    &:active,
    &:focus {
      background: $dark-Red !important;
      color: $white;
    }
  }
  &.btn-outline-red {
    color: $red;
    font-weight: $semi-bold;
    border-color: $red;
    line-height: 26px;
    border-width: 2px;

    &:hover,
    &:active,
    &:focus {
      background: $red !important;
      color: $white;
    }
  }

  &.btn-light {
    background: none;
    font-weight: $semi-bold;
    border: none;
    line-height: 26px;
    color: $grey-primary;

    &:hover,
    &:active,
    &:focus {
      color: $blue;
      background: none !important;
      box-shadow: none !important;
    }
  }

  &.btn-red-link {
    background: none;
    font-weight: $semi-bold;
    border: none;
    line-height: 26px;
    color: $red;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: $red !important;
      text-decoration: none;
      background: none !important;
      box-shadow: none !important;
    }
  }
  &.btn-orange {
    background: #fadcc6;
    font-weight: $semi-bold;
    border: none;
    line-height: 26px;
    color: #9a8142;

    &:hover,
    &:active,
    &:focus {
      background: #ffd2b3 !important;
    }
  }
  &.btn-light-green {
    background: #effff7 !important;
    color: #42b72a;
    border: none;
    &:hover,
    &:active,
    &:focus {
      background: #42b72a !important;
      color: $white;
    }
  }

  &.btn-modal-footer {
    padding-left: 45px;
    padding-right: 45px;
  }

  &.btn-modal-location {
    padding-left: 45px;
    padding-right: 45px;
  }

  &.btn-icon {
    background-color: $icon-bg;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    padding: 0;
    text-align: center;
    line-height: 20px;
    img {
      width: 100%;
    }
    svg {
      margin: 0;

      path {
        fill: $blue;
      }
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $blue !important;
      svg {
        margin: 0;

        path {
          fill: $white;
        }
      }
    }
    &.fullwidth {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  &.btn-icononly {
    min-width: auto !important;
    background-color: $icon-bg;
    border-radius: 7px;
    height: auto;
    padding: 6px 16px !important;

    svg {
      margin-top: 0;
      margin-right: 0;
      max-width: 25px;

      path {
        fill: $blue;
      }
    }

    &:hover {
      background-color: $blue !important;
      svg {
        path {
          fill: $white;
        }
      }
      &:hover {
        background-color: $blue !important;
        svg {
          path {
            fill: $white;
          }
        }
      }
    }
    &:focus {
      background-color: $icon-bg !important;
    }
  }
  &.btn-icontrans {
    min-width: auto !important;
    background-color: transparent;
    border-radius: 50px;
    height: 40px;
    width: 40px;
    padding: 0 !important;

    svg {
      margin-top: 0;
      margin-right: 0;
      max-width: 25px;

      path {
        fill: $grey-dark;
      }
    }

    &:hover {
      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  &.btn-sm {
    height: auto !important;
    padding: 5px 20px !important;
    @include font-14;
    svg {
      margin-right: 2px;
      width: 20px;
    }
    &.min-w-100 {
      min-width: 100px !important;
    }
  }
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-primary:active,
.show > .btn-success.dropdown-toggle {
  background: #4296c1 !important;
  color: $white !important;
}
.btn-inactive {
  background-color: $grey-light !important;
}

//Modal Style
.modal {
  &.mx-w-725 {
    .modal-dialog {
      max-width: 725px;
    }
  }
  &.mx-w-600 {
    .modal-dialog {
      max-width: 600px;
    }
  }
  &.mx-w-550 {
    .modal-dialog {
      max-width: 550px;
    }
  }
  &.mx-w-450 {
    .modal-dialog {
      max-width: 450px;
    }
  }
  &.mx-w-400 {
    .modal-dialog {
      max-width: 400px;
    }
  }
  &.mx-w-350 {
    .modal-dialog {
      max-width: 350px;
    }
  }

  .border-bottom,
  .border-top {
    border-color: $light-gray-border !important;
  }

  &.modal-xxl {
    .modal-xl {
      @media (min-width: 1500px) {
        max-width: 1450px;
      }
    }
  }
}

.modal-content {
  @include modal-radius;
}

.modal-header {
  padding: 36px 36px 0 36px;
  border-bottom: none;

  .modal-title {
    @include modal-head;

    span {
      display: block;
      @include h4-font;
      font-weight: $regular;
      color: $grey-light;
      margin-bottom: 10px;
    }
  }
}

.modal-body {
  padding: 36px;

  p {
    color: $grey-light;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      &.reg-dtls {
        color: $blue-primary;
      }
    }
  }
}

.modal-footer {
  padding: 0 36px 36px 36px;
  border-top: none;

  .mr-15 {
    margin-right: 15px;
  }
}

.payhistory {
  .modal-header {
    border-bottom: 1px solid #efefef;
    padding-bottom: 30px;
  }

  th {
    background: none !important;
  }
}

//Gif Loader Modal
.gifloader {
  .modal-content {
    background: none;
    border: none;

    .modal-body {
      padding: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.content-nav {
  width: 1640px;
  max-width: 96%;
  margin: 0 auto;

  &.contnt-container-margin {
    margin-top: 195px;

    @media (max-width: 1450px) {
      margin-top: 140px;
    }

    @media (max-width: 700px) {
      margin-top: 110px;
    }

    &.not-found-wrap {
      margin-top: 70px;
      margin-bottom: 70px;

      .not-found {
        background: url(../assets/404.svg) center bottom no-repeat $white;
        min-height: calc(100vh - 140px);
        display: flex;
        align-items: center;
        padding: 50px 30px;
        flex-direction: column;

        @media (max-width: 1200px) {
          background-size: contain;
        }

        h3 {
          font-size: 120px;
          color: $blue-primary;
          font-weight: $extra-bold;
        }

        p {
          font-size: 30px;
          color: $grey-primary;
          font-weight: $semi-bold;

          @media (max-width: 576px) {
            text-align: center;
          }
        }
      }
    }
  }

  .back-navigation {
    .back-btn {
      margin-bottom: 35px;
      display: flex;
      align-items: center;

      @media (max-width: 700px) {
        margin-bottom: 25px;
      }
    }
    .breadcrumb {
      margin-bottom: 35px;
    }
  }

  .content-nav-sub {
    margin-bottom: 70px;
    background: $white;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 4px;

    @media (max-width: 1024px) {
      margin-bottom: 40px;
    }

    @media (max-width: 700px) {
      margin-bottom: 20px;
    }

    .content-nav-header {
      padding: 20px 30px;
      border-bottom: 1px solid #efefef;

      @media (min-width: 1024px) {
        min-height: 80px;
      }

      @media (max-width: 700px) {
        padding: 20px 15px;
      }

      .btn {
        @media (min-width: 1024px) {
          min-width: 175px;
          padding: 0 25px;
        }
      }
      h2 {
        display: flex;
        a {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          svg {
            margin-right: 0;
          }
          &:hover {
            path {
              fill: $blue;
            }
          }
        }
      }
    }

    .content-filter-main {
      padding: 25px 30px 38px;
      border-bottom: 1px solid #efefef;

      @media (max-width: 700px) {
        padding: 20px 15px 30px;
      }

      &.table-filter {
        padding-bottom: 25px;

        .icon-wrap {
          svg {
            path {
              fill: $grey-light !important;
            }
          }
        }
      }
      > div a {
        text-decoration: underline;
      }
    }

    .form-check-label {
      color: $grey-light;
    }

    p {
      color: $grey-light;
      margin-bottom: 35px;
    }

    .content-container-padding {
      padding: 38px 30px;

      @media (max-width: 700px) {
        padding: 20px 15px;
      }
      .dash-details {
        .graph-category {
          //text-align: center;
          margin-top: 15px;
          padding-left: 20px;
          span {
            &::before {
              content: '';
              display: inline-block;
              width: 14px;
              height: 14px;
              border-radius: 3px;
              position: absolute;
              margin-left: -22px;
              margin-top: 5px;
            }
          }
          &.graph-weekly {
            span {
              &::before {
                border: #489cff solid 3px;
              }
            }
          }
          &.graph-monthly {
            span {
              &::before {
                border: #4296c1 solid 3px;
              }
            }
          }
          &.graph-yearly {
            span {
              &::before {
                border: #11399f solid 3px;
              }
            }
          }
        }
        > .col {
          border: 0 !important;
          padding: 0 !important;
          @media (max-width: 1024px) {
            margin-bottom: 30px;
          }

          .chart-header {
            input {
              display: none !important;
            }
          }

          .col {
            border: $grey-border solid 1px;
            margin: 0 15px;
            padding: 80px 20px 0px;

            &.active {
              border-color: $blue;
            }

            .form-group {
              z-index: 999;
            }
          }
          .graph-btn-nav{
            margin-top: 10px;
            .col{
            padding: 0;
            border: 0;
            p{
              margin-bottom: 0;
              margin-top: 0;
            }
          }
          .next_prev{
            display: flex;
            justify-content: end;
            a{
              display: inline-flex;
              align-items: center;
              color: $grey-light;
              svg{
                margin: 0 7px;
              }
              &.disabled-link{
                color: $grey-border;
                svg{
                  path{
                    fill: $grey-border
                  }
                }
              }
            }
          }
          }
        }
      }
    }

    .btn-link {
      color: $grey-light;
      min-width: auto !important;

      &:hover {
        color: $blue;
      }

      &:focus {
        box-shadow: none;
      }

      &[aria-expanded~='true'] {
        border-bottom-color: $blue !important;

        span {
          svg {
            transform: rotate(180deg);
          }
        }
      }

      svg {
        path {
          fill: $grey-light !important;
        }
      }

      &.btn-filter {
        height: 46px;
        padding: 0;
        border-bottom: 2px solid #efefef;
        border-radius: 0;
        text-decoration: none;

        svg {
          margin-right: 0;
        }

        span {
          margin-left: 5px;
        }
      }
    }

    .basic-info {
      margin-bottom: 20px;

      .type-origin {
        label {
          font-weight: $semi-bold;
          margin-bottom: 16px;
        }

        .form-check {
          label {
            font-weight: $regular;
            margin: 0 25px 0 0;
            color: $grey-light;
          }
        }
      }

      .delivery-window {
        .mt-16 {
          margin-top: 16px;
        }
      }

      label {
        &.commonlabel {
          font-weight: $semi-bold;
          margin-bottom: 16px;
        }
      }

      .terms {
        .form-group {
          margin: 0;
        }

        .checkbox-group {
          .check-wrap {
            height: 40px;
          }
        }
      }
    }

    &.general-notes {
      h4 {
        margin-bottom: 18px;
        font-weight: $regular;
      }

      .note-save-info {
        @include common-font;
        color: $grey-light;
        padding-left: 36px;

        @media (max-width: 576px) {
          padding: 0;
        }

        span {
          font-weight: $semi-bold;
          color: $grey-primary;
        }
      }

      .btn-wrap {
        .btn-outline-primary {
          margin-left: 36px;
        }

        @media (max-width: 576px) {
          flex-direction: column;
        }

        .btn {
          @media (max-width: 576px) {
            margin: 0;
            margin-bottom: 20px;
          }
        }
      }
    }

    &.saved-notes {
      padding: 20px 30px;

      h4 {
        @include h4-font;
        color: $grey-primary;
        font-weight: $semi-bold;
        margin-bottom: 36px;
      }
    }
  }
}
.support-links {
  margin-bottom: 70px;

  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }

  @media (max-width: 700px) {
    margin-bottom: 20px;
  }

  .btn {
    @media (min-width: 1024px) {
      min-width: 320px;
    }
  }
}

// Table Style
.table-responsive {
  min-height: 250px;
  overflow-x: auto !important;
}
.table {
  min-width: 1200px;
  &.min-w-100per {
    min-width: 100%;
  }
  &.tr-pointer {
    tr {
      cursor: pointer;
      &:hover {
        background-color: $grey-bg;
      }
    }
  }

  th {
    color: $grey-primary;
    font-weight: $semi-bold;
    background-color: $blue-socondary;
    border: 0;
    vertical-align: middle;
    height: 55px;

    &:first-child {
      padding-left: 30px;
    }

    &:last-child {
      padding-right: 30px;
    }
    &.w-90 {
      width: 90px;
    }
    &.w-120 {
      width: 120px;
    }
    &.w-150 {
      width: 150px;
    }
    &.w-170 {
      width: 170px;
    }
    &.w-180 {
      width: 180px;
    }
    &.w-200 {
      width: 200px;
    }

    &.w-220 {
      width: 220px;
    }

    &.w-250 {
      width: 250px;
    }
    &.w-300 {
      width: 300px;
    }
    &.w-350 {
      width: 350px;
    }
    &.w-420 {
      width: 420px;
    }
    svg {
      margin-left: 10px;
    }
  }

  tbody {
    border-top: 0 !important;

    tr {
      border-top: 1px solid #efefef;
      height: 60px;
      vertical-align: middle;
      color: $grey-light;

      &.unread {
        font-weight: $semi-bold;
        color: $grey-primary;
      }
      &.disabled {
        color: $grey-border;
        .react-dropdown-select {
          pointer-events: none;
          opacity: 0.4;
        }
        img {
          opacity: 0.4;
        }
      }
      &.assigned {
        background: #f1f1f1;
      }
      &.error-item {
        td {
          background-color: $red-light;
          color: $red;
        }
      }
      &.red-item {
        td {
          color: $red;
          a {
            color: $red;
          }
        }
      }
      &.verified {
        position: relative;
        &::after {
          content: '';
          //background: url(../assets/GreenTick.svg);
          background-color: green;
          position: absolute;
          width: 20px;
          height: 20px;
          left: 6px;
          top: 18px;
        }
      }
      &.approved {
        td {
          color: $green;
        }
      }
      td {
        border-bottom: 0;
        word-break: break-word;
        &:first-child {
          padding-left: 30px;
        }

        &:last-child {
          padding-right: 30px;
        }

        &.delete {
          svg {
            fill: $red !important;
            cursor: pointer;
          }
        }
        .similar-items-img {
          background-color: $table-grey;
        }
      }
      &:last-child {
        border-bottom: 1px solid #efefef;
      }
      &.bg-white {
        td {
          background-color: $white;
          box-shadow: none;
        }
      }
      &.vertical-top {
        vertical-align: top;
      }
    }
  }

  tfoot {
    border-top: $light-gray-border solid 1px !important;

    tr {
      @include font-18;

      &:first-child {
        td {
          padding-top: 30px;
        }
      }
      td {
        border-bottom: 0;
        &:last-child {
          padding-right: 30px;
        }
      }
    }
  }

  &.table-second {
    th {
      background-color: transparent;

      &:first-child {
        padding-left: 50px;
      }
    }

    tbody {
      tr {
        background-color: $table-grey;
        border-bottom: 12px solid $white;
        border-top: 12px solid $white;
        height: 95px;

        td {
          &:first-child {
            padding-left: 50px;
          }

          .bg-span {
            width: 52px;
            height: 52px;
            background-color: #ebebeb;
            display: flex;
            border-radius: 50%;
            padding: 12px;
            justify-content: center;

            img {
              max-width: 30px;
            }

            svg {
              max-width: 30px;
            }
          }
        }
      }
    }
  }

  .btn {
    min-width: auto !important;
    white-space: nowrap;
  }

  .form-control,
  .form-select {
    &.form-control-sm {
      height: auto;
    }
  }

  &.table-third {
    th {
      background: #f5f5f5;
    }
  }

  &.table-sm {
    th,
    tr {
      height: auto;
      @include font-14;
    }
  }

  &.tr-120 {
    tbody {
      tr {
        height: 120px;
      }
    }
  }

  &.merge-contacts {
    a {
      color: $blue-primary;

      svg {
        fill: $blue-primary;
      }
    }
  }
  &.table-striped {
    tr {
      border: 0;
      td {
        color: $grey-light;
      }
    }
    &.similaritems {
      tr {
        td {
          &:first-child {
            font-weight: $semi-bold;
            color: $grey-primary;
          }
          &.bold-text {
            font-weight: $semi-bold;
            color: $grey-primary;
          }
        }
      }
    }
  }
}

// Select with icon
.select-icon-outer {
  .select-with-icon {
    &.ps-15 {
      .select_icon__control {
        padding-left: 15px;
      }
    }
    .select_icon__control {
      height: 52px;
      border-color: $grey-border;
      padding-left: 52px;
      @include common-font;

      &.select_icon__control--is-focused {
        box-shadow: none;
        border-color: $blue;
      }

      .select_icon__placeholder {
        color: $grey-light;
      }

      .select_icon__input-container {
        color: $grey-primary;
      }

      .select_icon__value-container {
        padding: 0;
      }

      .select_icon__indicator-separator {
        display: none;
      }

      &.select_icon__control--menu-is-open {
        .select_icon__indicator {
          transform: rotate(180deg);
        }
      }

      .select_icon__indicator {
        color: $grey-light;
      }
    }

    .select_icon__menu {
      margin: 0;
      box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.14);
      min-width: 225px;

      .select_icon__option {
        padding: 12px 25px;
        display: flex;
        cursor: pointer;
        @include common-font;

        img {
          max-width: 16px;
          margin-right: 16px;
        }

        &.select_icon__option--is-focused {
          background-color: $white;
        }

        &.select_icon__option--is-selected {
          background-color: #deebff;
          color: inherit;
        }
      }
    }
    &.payment {
      .select_icon__menu {
        .select_icon__option {
          align-items: center;
          img {
            max-width: 30px;
            margin-right: 16px;
          }
        }
      }
      .selcted-role-icon {
        img {
          max-width: 30px;
          margin-right: 16px;
        }
      }
    }
  }

  > svg {
    position: absolute;
    top: 15px;
    left: 18px;
  }

  &.choose-icon-drop {
    max-width: 325px;
    @media (min-width: 1024px) {
      min-width: 175px;
    }
    .select_icon__menu-list {
      display: flex;
      flex-wrap: wrap;
      padding: 24px 12px 0;

      .select_icon__option {
        flex: 0 0 24%;
        padding: 0;
        // max-width: 48px;
        height: 48px;
        background-color: #fbfbfb;
        margin-right: 9px;
        margin-left: 9px;
        justify-content: center;
        margin-bottom: 24px;
        border-radius: 4px;

        &:hover {
          background-color: #fbfbfb;
        }

        &.select_icon__option--is-focused {
          background-color: #fbfbfb;
        }

        &.select_icon__option--is-selected {
          background-color: #deebff;
          color: inherit;
        }

        img {
          max-width: 24px;
          margin-right: 0;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .mt-mx-lg-top {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .mt-mx-md-top {
    margin-top: 20px;
  }
}

.empty-min-height {
  min-height: calc(100vh - 385px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 1450px) {
    min-height: calc(100vh - 415px);
  }

  @media (max-width: 700px) {
    min-height: calc(100vh - 356px);
  }
}

.mad-hidden {
  display: none !important;
}

// Pagination

.pagination {
  margin-bottom: 0;
  .page-item {
    &.active {
      .page-link {
        background-color: $blue;
        border-color: $blue;
        color: $white;
      }
    }

    .page-link {
      color: $grey-light;
      border-color: $light-gray-border;
    }
  }

  li {
    box-shadow: none;

    a {
      // font-size: $main-fs;
      // font-weight: $medium;
      color: $grey-light;
      padding: 8px 15px;
      border: $light-gray-border solid 1px;
      display: inline-flex;

      &:hover {
        color: $blue;
      }
    }

    &.pagination__link--active {
      a {
        color: $white !important;
        background: $blue;
        padding: 9px 15px;
        border: none;
      }
    }
  }
}

.gray-lg-tooltip {
  .tooltip-inner {
    max-width: 550px;
    @include font-14;
    @include modal-radius;
    padding: 16px;
    background-color: $light-gray-border;
    opacity: 1;
    color: $grey-light;
  }

  .tooltip-arrow {
    &::before {
      border-top-color: $light-gray-border;
      border-bottom-color: $light-gray-border;
    }
  }

  &.show {
    opacity: 1;
  }

  &.table-tooltop {
    max-width: 225px;
  }
  &.bs-tooltip-end {
    .tooltip-arrow {
      &::before {
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        border-right-color: #efefef;
        border-left-color: #efefef;
      }
    }
  }
}
.pop-tooltip {
  padding: 0 !important;
  min-width: 300px;
  .popover-header {
    background-color: transparent;
    border-bottom: 0;
    padding: 10px 15px 5px;
    &::before {
      border-bottom: 0;
    }
  }
  .popover-body {
    padding: 0 15px 15px;
    color: $grey-light;
  }
  .popover-arrow {
    &::before {
      border: 0;
    }
  }
}

.helper-tooltip {
  .tooltip-inner {
    max-width: 350px;
    @include modal-radius;
    padding: 16px;
    background-color: #c4c4c4;
    opacity: 1;
    color: $grey-primary;
  }

  .tooltip-arrow {
    &::before {
      border-top-color: #c4c4c4;
    }
  }

  &.show {
    opacity: 1;
  }
}

.indicator-active {
  &::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #f46a6a;
    position: absolute;
    margin-left: -7px;
  }
}

// Calendar

.custom-calendar {
  position: relative;
  .date-custom-label {
    position: absolute;
    top: -25px;
    color: $grey-light;
  }
  &.disabled {
    .form-control {
      border: 0;
      background-color: $light-gray-border;
      color: $grey-light;
      cursor: default;
    }
  }
  .react-datepicker__header__dropdown {
    display: flex;
    justify-content: center;
    color: $white;
    .react-datepicker__month-dropdown-container {
      margin-right: 30px;
    }
    .react-datepicker__year-read-view--down-arrow {
      width: 7px;
      height: 7px;
      top: 3px;
    }
    .react-datepicker__month-read-view--down-arrow {
      width: 7px;
      height: 7px;
      top: 4px;
    }
    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown {
      color: $grey-dark;
      border: 0;
      .react-datepicker__year-option,
      .react-datepicker__month-option {
        padding: 3px 0;
      }
    }
    .react-datepicker__navigation--years {
      display: none;
    }
  }
  .react-datepicker {
    border: none;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24);

    .react-datepicker__triangle {
      &::after {
        border-bottom-color: $blue !important;
      }

      &::before {
        border-bottom-color: transparent !important;
      }
    }

    .react-datepicker__header {
      border-bottom: 0;
      background-color: $blue;

      .react-datepicker__current-month,
      .react-datepicker__day-name {
        color: $white;
      }
    }

    .react-datepicker__navigation-icon {
      top: 5px;
    }

    .react-datepicker__day--selected {
      background-color: $blue;
      border-radius: 50%;
    }

    .react-datepicker__day:hover {
      border-radius: 50%;
    }

    .react-datepicker__day--keyboard-selected {
      border-radius: 50%;
      background-color: $blue;
      color: $white;
    }
  }

  .react-datepicker__input-container {
    &::before {
      content: '';
      background: url('../assets/CalendarGray.svg') no-repeat;
      width: 24px;
      height: 26px;
      position: absolute;
      display: block;
      top: 15px;
      left: 15px;
    }

    input {
      height: 52px;
      border: 1px solid $grey-border;
      padding-left: 50px;
      @include b-radius;
      background: url('../assets/DownBlue.svg') no-repeat;
      background-position: right 15px top 50%;
      width: 100%;

      &.react-datepicker-ignore-onclickoutside {
        background: url('../assets/UpBlue.svg') no-repeat;
        background-position: right 15px top 50%;
      }

      &:focus-visible {
        border-color: $blue;
        outline: 0;
      }
    }

    button {
      text-align: left;
      padding-left: 50px;
      padding-right: 30px;
    }
  }
}

.modal-body {
  .custom-calendar {
    .react-datepicker__input-container {
      &::before {
        top: 17px;
      }
    }
  }
  .table {
    min-width: auto;
  }
}

.badge {
  @include common-font;
  padding: 12px 22px;
  @include modal-radius;
  font-weight: inherit;
  &.badge-accepted {
    color: #42B72A;
    background: #E5FFF2 !important;
}

  &.badge-not-accepted {
      background: #CCCABA !important;
      color: #fff;
  }

  &.badge-catchweight {
      background: #79D6BF !important;
      color: #1F3349;
  }

  &.badge-pending {
      background: #FAFAFA !important;
      color: #63707E;
  }

  &.badge-modified {
      background: #FFF2E3 !important;
      color: #FF9B26;
  }

  &.badge-review {
      background: #FFEEEE !important;
      color: #D50000;
  }

  &.badge-discripancy {
      background: #FFEEEE !important;
      color: #D50000;
  }

  &.badge-delivered {
      background: rgba(16, 121, 173, 0.1) !important;
      color: #1079AD;
  }

  &.badge-inprogress {
      background: #C1E9FD !important;
      color: #075C86;
  }

  &.badge-due-low {
      background: #FFF1F1 !important;
      color: #D5C000;
  }

  &.badge-due-medium {
      background: #FFF1F1 !important;
      color: #FD5B00;
  }

  &.badge-due-high {
      background: #FFF1F1 !important;
      color: #FF0606;
  }

  &.badge-due-paid {
      background: #EFFFF7 !important;
      color: #42B72A;
  }

  &.badge-not-received {
      background: #DBDDDF !important;
      color: #63707E;
  }

  &.badge-discrepancy {
      background: #B5FFF2 !important;
      color: #075C86;
  }

  &.badge-premium {
      background: #AAE38F !important;
      color: #1F3349;
  }

  &.badge-delivering {
      background: #d9f1d4 !important;
      color: #42B72A;
  }

  &.badge-cancelled {
      background: #BEC1C5 !important;
      color: $white;
  }

  &.badge-mintoreview {
      background: #FFEEEE !important;
      color: #D50000;
  }

  &.badge-picking {
      background: #F8E6FF !important;
      color: $grey-light;
  }

  &.badge-loading {
      background: #B1ECF9 !important;
      color: $grey-light;
  }

  &.badge-placed {
      color: #42B72A;
      background: #E5FFF2 !important;
  }

  &.badge-sm {
      padding: 10px 22px;
      @include font-14;
  }
}

.restaurant-btns {
  display: flex;
  flex-direction: column;

  @media (max-width: 992px) {
    margin-top: 30px;
  }

  button {
    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .top-btns {
    margin-bottom: 15px;

    button {
      &:first-child {
        margin-right: 30px;

        @media (max-width: 576px) {
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
    }
  }

  .est-btns {
    span {
      right: -3px;
      min-width: 28px;
      height: 28px;
      top: -6px;
      background: #f46a6a;
      border-radius: 14px;
      border: 2px solid $white;
      color: $white;
      @include font-14;
    }

    button {
      @media (max-width: 992px) {
        width: 100%;
      }

      @media (max-width: 576px) {
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

//Breadcrumbs
.breadcrumb {
  .breadcrumb-item {
    @include font-16;
    color: $btn-prm-hover;
    a {
      color: $grey-light !important;

      &:hover,
      &:active,
      &:focus {
        color: $grey-light;
      }
    }
  }
}

.establishments {
  td {
    &.decline {
      a {
        color: $red;
        text-decoration: underline;

        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }
      }
    }

    &.approve {
      a {
        color: #42b72a;
        text-decoration: underline;

        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
}
.text-lightgray {
  color: $grey-light;
}
.text-blue {
  color: $blue-light !important;
}

.hidden {
  display: none;
}
.text-red {
  color: $red;
}
.text-green {
  color: $green;
}
.text-yellow {
  color: #d5c000;
}
.w-50px {
  width: 50px;
}
.w-75px {
  width: 75px;
}
.w-100px {
  width: 100px;
}

.w-100px-formcontrol {
  .cust-label-group {
    width: 100px;
  }
}
.w-125px {
  width: 100px;
}
.w-150 {
  width: 150px;
}
.w-165 {
  width: 165px;
}
.w-225 {
  width: 225px;
}
.w-35per {
  width: 35%;
}
.w-90per {
  width: 90%;
}
.pt-14 {
  padding-top: 14px;
}
.pt-37 {
  padding-top: 37px;
}
.mt-m4 {
  margin-top: -4px;
}
.text-small {
  font-size: 13px;
}
.semi-bold {
  font-weight: $semi-bold;
}
.margin-pb-10-container {
  p {
    margin-bottom: 10px !important;
    b {
      color: $grey-primary;
    }
  }
}
//Image uploader
.image-uploader {
  border: 2px $blue-primary dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  min-height: calc(100% - 24px);
  max-height: calc(100% - 24px);
  padding: 20px;

  img {
    max-height: 100%;
    max-width: 200px;
  }

  .drag-icon {
    svg {
      fill: $blue-primary;
    }
  }

  .or {
    color: #e0e0e0;
    @include font-14;
    font-weight: $semi-bold;
    padding: 15px 0;
  }

  .upload {
    display: flex;
    align-items: center;
    @include font-18;
    color: $blue-primary;

    svg {
      fill: $blue-primary;
      margin-right: 14px;
    }
  }
}

.profile-edit {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  position: relative;
  .image-uploader {
    max-height: 150px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    z-index: 2;
    transition: 0.3s;
    border: 0;
    padding: 0;
    &:hover {
      opacity: 0.5;
    }
    img{
      width: 100%;
  }
  }
  .profile-img-hover {
    width: 150px;
    height: 150px;
    background-color: #075c86;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      path {
        fill: $white;
      }
    }
  }

  .profile-pic-edit {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: #4296c1;
    cursor: pointer;
    bottom: 0;
    right: 0;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    //box-shadow: 0px 4px 19px rgb(0 0 0 / 10%);
    svg {
      width: 18px;
      fill: #fff;
      transition: 0.3s;
      margin: 0 auto;
      align-items: center;
    }
  }
}

//Invite Modal
.invite {
  .add-remove {
    svg {
      cursor: pointer;
      margin-top: 8px;

      path {
        fill: $blue-primary;
      }

      circle {
        stroke: $blue-primary;
      }

      rect {
        stroke: $blue-primary;
      }

      &:hover {
        path {
          fill: $blue-light;
        }

        circle {
          stroke: $blue-light;
        }

        rect {
          stroke: $blue-light;
        }
      }
    }
  }

  .btn-light {
    margin-right: 15px;
  }

  .row {
    @media (max-width: 576px) {
      flex-wrap: inherit;
    }

    .col-sm-10 {
      @media (max-width: 576px) {
        width: 260px;
      }
    }

    .col-sm-2 {
      @media (max-width: 576px) {
        padding: 0;
      }
    }
  }
}

.restaurant-info {
  //@include h4-font;

  .row {
    margin-bottom: 25px;

    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
    }
  }

  .label {
    color: $grey-primary;
    font-weight: $semi-bold;
    max-width: 300px;
  }

  .value {
    color: $grey-light;
  }
}

.name-card {
  @include b-radius;
  border: 3px solid $blue-primary;

  @media (max-width: 1200px) {
    margin-bottom: 20px;
  }

  .top-wrap {
    padding: 18px;
    border-bottom: 1px solid #e0e0e0;
    @include font-18;
    font-weight: $semi-bold;

    span {
      @include common-font;
      font-weight: $regular;
      color: $grey-light;
    }
  }

  .bottom-wrap {
    @include common-font;
    color: $grey-light;
    padding: 18px;

    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: flex-start !important;
    }

    svg {
      fill: $blue-primary;
      margin-right: 10px;
    }

    span {
      display: block;
      &:first-child {
        padding-right: 24px;
        margin-bottom: 10px;
      }

      @media (max-width: 1200px) {
        margin-right: 0;
        margin-bottom: 15px;
      }

      &:last-child {
        @media (max-width: 1200px) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.buttons-wrap {
  button {
    margin-right: 36px;

    @media (max-width: 992px) {
      margin-right: 10px;
    }
  }
}

.icon-blue {
  svg {
    path {
      fill: $blue-light;
    }
  }
}

.labelwithicon {
  svg {
    margin-right: 10px;
  }
}
.infinite-scroll-component {
  position: relative;
}

.loader-new {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url('../assets/floss.gif') no-repeat;
  background-color: rgba(0, 0, 0, 0.6);
  background-size: 100px;
  background-position: center;
  top: 0;
  left: 0;
  z-index: 11111;
}

.delivery-days {
  margin-bottom: 25px;

  label {
    display: block;
    @include common-font;
    margin-bottom: 12px;
    font-weight: $semi-bold;
  }

  .btn-group {
    width: 100%;

    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
    }

    &.error {
      label {
        border: 1px solid $red;
      }
    }

    label {
      background: none;
      border: 1px solid #e0e0e0;
      height: 52px;
      color: $grey-light;
      @include common-font;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;

      &:last-child {
        border-radius: 0 4px 4px 0;
      }

      &:nth-child(2) {
        border-radius: 4px 0 0 4px;
      }

      &:hover {
        background: #4296c1;
        color: $white !important;
      }

      &:focus,
      &:active {
        border: 1px solid #e0e0e0 !important;
        box-shadow: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }
    .btn {
      padding: initial !important;
    }
  }
}

.errorlist-tbl {
  max-height: 251px;
  overflow: auto;
  margin-bottom: 15px;
}
.notification-main {
  @include b-radius;
  background-color: $blue-socondary;
  p {
    margin-bottom: 5px !important;
  }
}
.alert-secondary {
  background-color: $grey-bg;
  color: $blue-primary;
  border: 0;
  @include common-font;
}
.blue-border {
  border: #4296c1 solid 2px;
}
.nav-tabs {
  .nav-item {
    &:first-child {
      .nav-link {
        padding-left: 0;
      }
    }
  }
  .nav-link {
    padding: 10px 75px;
    @include common-font;
    font-weight: $semi-bold;
    color: $grey-dark;
    border: 0;
    &:hover {
      border: 0;
    }
    &:focus {
      border: 0;
    }
    &:focus-visible {
      outline: 0;
    }
    &.active {
      border: none;
      border-bottom: $blue 3px solid;
      color: $blue;
    }
  }
  &.settingstab {
    .nav-link {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  &.role-permission {
    .nav-item {
      &:first-child {
        .nav-link {
          padding-left: 75px;
        }
      }
    }
  }
}
.tab-pane {
  min-height: 250px;
}
.react-multi-carousel-list {
  .similar-itemnav {
    margin: 0 15px;
    .similar-img-nav {
      background-color: $grey-bg;
      text-align: center;
      padding: 25px 0 50px 0;
      margin-bottom: 20px;
    }
    .item-name {
      @include common-font;
      font-weight: $semi-bold;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .rest-name {
      color: $blue-primary;
      text-transform: uppercase;
      text-align: right;
      @include font-14;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .react-multiple-carousel__arrow {
    background-color: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    top: 29%;
    &::before {
      color: $grey-light;
    }
  }
  .react-multiple-carousel__arrow--left {
    left: 2px;
  }
  .react-multiple-carousel__arrow--right {
    right: 2px;
  }
}
.ordersheet-imgnav {
  background-color: $grey-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.greencheck {
  max-width: 42px;
}
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
}
.number-input {
  border-radius: 4px;
  button {
    background-color: $blue-primary;
    border: 0;
    border-radius: 0;
    width: 52px;
    padding: 0;
    color: $white !important;
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
    svg {
      margin-right: 0;
      path {
        fill: $white;
      }
    }
  }
}

.notification-count {
  right: -3px;
  min-width: 28px;
  height: 28px;
  top: -6px;
  background: #f46a6a;
  border-radius: 14px;
  border: 2px solid #fff;
  color: #fff;
  font-size: 14px;
}
.yes-no {
  .btn {
    font-weight: normal;
    outline: none !important;
    box-shadow: none !important;
    &.btn-primary {
      background-color: $grey-bg;
      color: $grey-dark;
      margin: 0;
      &:hover {
        color: $white;
      }
    }
  }
  .btn-check[value='1'] {
    & + .btn-primary {
      background-color: $blue;
      color: $white;
      outline: 0;
    }
  }
}

.popover {
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  padding: 15px;
  border: 0;
}
.no-margin {
  * {
    margin: 0;
  }
  .cust-ic-group{
    margin-bottom: 0;
  }
}
.move {
  cursor: move;
}
.inactive {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba($color: #fff, $alpha: 0.7);
  z-index: 111;
}

.order-paused {
  background-color: #f2ce30;
  color: $grey-primary;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: $semi-bold;
}
.cursor-pointer {
  cursor: pointer;
}
.privacy-policy {
  p {
    margin-bottom: 20px !important;
  }
  h5 {
    margin-bottom: 15px;
    margin-top: 30px;
    &.light {
      color: $grey-light;
      font-weight: normal;
      margin-top: 0;
    }
  }
  h6 {
    color: $grey-primary;
    margin-bottom: 10px;
    margin-top: 30px;
  }
  ul {
    li {
      color: $grey-light;
      margin-bottom: 15px;
    }
  }
}
.more-actions {
  .dropdown-toggle {
    border: 0;
    padding: 0 15px;
    svg {
      margin-right: 0;
    }

    &::after {
      display: none;
    }

    &[aria-expanded~='true'] {
      border-bottom: 0 !important;
    }
  }
  &.drop-arrow {
    .btn {
      height: auto;
      padding: 0 5px;
    }
  }
  .dropdown-menu {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24);
    padding: 0;
    border: 0;
    .dropdown-item {
      padding: 15px 25px;
      border-bottom: 1px solid $light-gray-border;
      color: $grey-light;
      &:focus,
      &:hover,
      &:active {
        background-color: #e9ecef;
      }
    }
  }
}
.secondary-tab {
  padding: 20px 30px;
  &.nav-tabs {
    .nav-link {
      border: $grey-border solid 1px;
      border-radius: 30px;
      @include h4-font;
      color: $grey-primary;
      padding: 10px 60px 13px !important;
      margin-right: 30px;
      &.active {
        background-color: $blue;
        border-color: $blue;
        color: $white;
      }
    }
  }
}
.button-dropdown {
  .dropdown-toggle {
    text-decoration: none;
    &:focus {
      box-shadow: none;
    }
    &:after {
      display: none;
      border: none;
    }
  }
  .button-dropdownmenu {
    display: flex;
    align-items: center;
    color: $white;
    svg {
      margin-right: 5px;
      margin-top: 0;
      width: 20px;
      height: 20px;
    }
    &:after {
      content: '';
      display: inline-block;
      background: url('../assets/DownArrow.svg');
      width: 24px;
      height: 24px;
      margin-left: 5px;
    }
  }
  &.show {
    .button-dropdownmenu {
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-menu {
    border: 0;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24);
    padding-bottom: 15px;
    left: auto;
    right: 0;
    width: 100%;
    border-radius: 30px;

    .dropdown-item {
      color: $grey-light !important;
      padding-top: 10px;
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      @include common-font;
      &:focus,
      &:hover,
      &:active {
        background-color: #e9ecef;
      }
      svg {
        margin-right: 12px;
        max-width: 19px;
        max-height: 19px;
        path {
          fill: $grey-light;
        }
      }
    }
  }
}
.dollarinput {
  background: url('../assets/Dollaricon.svg') no-repeat 13px 17px;
  padding-left: 35px;
}
.form-main {
  > div:nth-child(even) {
    margin-left: 8.3333%;
    @media (max-width: 1023px) {
      margin-left: 0;
    }
  }
}
//Google Location Search
.pac-container {
  min-width: 600px;
  .pac-item {
    font-family: $primary-font;
    padding: 13px 15px;
    @include common-font;
    color: #63707e;
    cursor: pointer;

    .pac-icon {
      background: url(../assets/LocationSearch.svg) 0 0 no-repeat;
      width: 24px;
      height: 24px;
      margin: 4px 14px 0 0;
    }

    span {
      &.pac-item-query {
        font-family: $primary-font;
        @include common-font;
        font-weight: $regular;
        color: #63707e;

        .pac-matched {
          font-family: $primary-font;
          @include common-font;
          color: $blue;
          font-weight: $medium;
        }
      }
    }
  }
}

.min-h-auto {
  min-height: auto !important;
}
.h-auto {
  * {
    height: auto !important;
  }
}
.no-item-found {
  color: $grey-light;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    path {
      fill: $grey-light;
    }
  }
}
.icon-gray {
  svg {
    path {
      fill: $grey-light;
    }
  }
}
.location-list {
  margin-bottom: 3rem;
  p {
    margin-bottom: 0 !important;
  }
}
.Toastify__toast {
  border-radius: 7px !important;
  min-height: auto !important;
  padding: 3px 7px 3px 3px !important;
  font-size: 14px !important;
}
.search-auto {
  input {
    padding-left: 45px;
  }
  svg {
    position: absolute;
    top: 13px;
    left: 13px;
    path {
      fill: $grey-light;
    }
  }
}
.rbt-menu {
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.24);
  border: none !important;

  .rbt-highlight-text {
    padding: 0;
    background-color: transparent;
    font-weight: $semi-bold;
    color: $grey-primary;
  }

  .dropdown-item {
    @include font-14;
    color: $grey-light !important;
    padding: 8px 15px 8px 8px;

    &.active {
      background-color: $blue-light;
      color: $white !important;

      mark {
        color: $white;
      }
    }
  }
}
.rbt {
  .rbt-aux {
    position: absolute;
    right: 5px;
    top: 3px;
    button {
      font-size: 11px;
      .rbt-close-content {
        font-size: 0;
      }
    }
  }
  input {
    padding-right: 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
//Numeric Input Style
.cust-numeric-group {
  margin-bottom: 25px;

  label {
    &.title {
      @include common-font;
      margin-bottom: 12px;
      font-weight: $semi-bold;
    }
  }

  .control-wrap {
    .dollar {
      width: 46px !important;
      height: 50px !important;
      position: absolute !important;
      left: 52px !important;
      background: #f3f3f3 !important;
      top: 1px !important;
      color: $grey-light !important;
      font-size: 20px !important;
      border-radius: 0 4px 4px 0 !important;
    }

    .react-numeric-input {
      width: 100% !important;
      height: 52px !important;
      display: block !important;

      &:hover,
      &:focus,
      &:active,
      &:focus-visible {
        input {
          border: 1px solid $blue !important;
          outline: none !important;
        }
      }

      input {
        width: 100%;
        text-align: left !important;
        border-radius: 4px !important;
        border: 1px solid $grey-border !important;
        height: 52px;
        padding-left: 110px !important;

        &:hover,
        &:focus,
        &:active,
        &:focus-visible {
          border: 1px solid $blue !important;
          outline: none !important;
        }

        &.error {
          border-color: $red;
        }

        &.active {
          border-color: $blue;
        }

        &.error {
          border: 1px solid $red !important;
        }

        &.active {
          border: 1px solid $blue !important;
        }
      }

      b {
        position: absolute;
        width: 52px !important;
        height: 52px !important;
        border: none !important;
        right: 0px !important;
        cursor: pointer !important;
        border-radius: 0 4px 4px 0 !important;
        background: url(../assets/Plus.svg) center center no-repeat $blue-primary !important;
        top: 0px !important;
        &:hover,
        &:active,
        &:focus {
          background: url(../assets/Plus.svg) center center no-repeat $blue !important;
        }

        &:last-child {
          left: 0px !important;
          top: 0px !important;
          right: inherit !important;
          border-radius: 4px 0 0 4px !important;
          background: url(../assets/Minus.svg) center center no-repeat $blue-primary !important;

          &:hover,
          &:active,
          &:focus {
            background: url(../assets/Minus.svg) center center no-repeat $blue !important;
          }
        }
        i {
          background: $white !important;
        }
      }
    }
  }
}
.similar-items-btnnav {
  position: absolute;
  right: 30px;
  top: 30px;
}
.text-lightblue {
  color: $blue-primary !important;
}
.dark_bold {
  font-weight: $semi-bold;
  color: $grey-primary;
}
.chart-header {
  z-index: 999;
  width: 84%;
  margin: 5% 8%;
}
.rbt-input-hint {
  display: none;
}
.hover-effect {
  display: inline-block;
  &:hover {
    color: $blue-primary;
  }
}
.notification-pop-main{
  background-color: $light-gray-border;
  box-shadow: 0px 0px 9px 3px rgba(0,0,0,0.16);
-webkit-box-shadow: 0px 0px 9px 3px rgba(0,0,0,0.16);
-moz-box-shadow: 0px 0px 9px 3px rgba(0,0,0,0.16);
padding: 25px;
border-radius: 10px;
}
.pr-5 {
  padding-right: 5px;
}
.disabled-link {
  pointer-events: none;
  color: #BEC1C5;
}
.wentwrong{
  width: 35%;
  margin: 50px 0 20px;
}