@import "variables.scss";

.vendordetails{
    border: $light-gray-border solid 1px;
    margin-bottom: 30px;
    .content-nav-header{
        min-height: auto !important;
        padding: 20px !important;
    }
    .content-container-padding{
        padding: 20px !important;
        .row{
            margin-bottom: 20px;
            .col{
                color: $grey-primary;
                &:last-child{
                    color: $grey-light;
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
        .vendor-role-list{
            .col{max-width: 300px; min-width: 300px; justify-content: space-between; border-radius: 4px; border: $light-gray-border solid 1px; padding: 10px; margin: 0 15px 30px;
            
            .role-img{
                width: 44px;
                height: 44px;
                background-color: $table-grey;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            small{
                font-size: 13px;
                color: $grey-light;
            }
        } 
        }
    }
    h5{
        margin-bottom: 0;
    }
    .para-m-0{
            p{margin-bottom: 0 !important;}
        }
}