@import "variables.scss";

.order-form {
    .form-fields {
        margin-bottom: 15px;
        &:last-child{margin-bottom: 0;}
        .form-control {
            height: auto;
            margin-left: 1rem;
            color: $blue-light;
            &.rbt-input-main{width: 350px;}
        }

        .form-control-sm {
            height: auto;
            color: $blue-light;

        }

        .form-label {
            white-space: nowrap;
            margin-bottom: 0;
        }
    }
}

.rbt-menu {
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.24);
    border: none !important;

    .rbt-highlight-text {
        padding: 0;
        background-color: transparent;
        font-weight: $semi-bold;
        color: $grey-primary;
    }

    .dropdown-item {
        @include font-14;
        color: $grey-light;

        &:active {
            background-color: $blue-light;
            color: $white;

            mark {
                color: $white;
            }
        }
    }
}

.add-items {
    .rbt {
        width: 100%;

        svg {
            position: absolute;
            top: 14px;
            left: 15px;

            path {
                fill: $grey-light;
            }
        }
    }

    .rbt-menu {

        .dropdown-item {
            padding: 8px 15px 8px 50px;


        }
    }

    .form-control {
        padding-left: 50px;
    }

    .btn {
        height: 52px !important;
    }
}

.dark_bold {
    font-weight: $semi-bold;
    color: $grey-primary;
}

.alternative-option {
    background-color: $grey-bg;
    padding: 10px 50px;

    .form-control {
        background-color: transparent;
    }
    .react-dropdown-select-content{
        min-width: 240px;
        span{
            text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 240px;
        }
    }
    .react-dropdown-select{
    .react-dropdown-select-item-selected{
        background-position: right -18px top 10px !important;
    }
}
}

.no-icon-select {
    .cust-ic-group {
        margin-bottom: 0;

        .icon-wrap:empty {
            display: none;
        }

        .cutom-select {
            border-color: $grey-border;
            padding-left: 17px !important;
        }
    }
}
.no-icon-input{
    .cust-ic-group{
    .form-control{
        padding-left: 0.75rem !important;
    }
}
}

.more-actions {
    .dropdown-toggle {
        border: 0;

        svg {
            margin-right: 0;
        }

        &::after {
            display: none;
        }

        &[aria-expanded~=true] {
            border-bottom: 0 !important;
        }
    }
    &.drop-arrow{
        .btn{
        height: auto;
        padding:0 5px;
    }
    }
    .dropdown-menu{
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24);
            padding: 0;
            border: 0;
            .dropdown-item{
                padding: 15px 25px;
                border-bottom: 1px solid $light-gray-border;
                color: $grey-light;
                &:focus,
                &:hover,
                &:active {
                    background-color: #e9ecef;  
                }
            }
    }
}
.sales-person{
    display: flex;
    align-items: center;
    justify-content: end;
    svg{
        width: 20px;
        margin-right: 5px;
        path{
            fill: $grey-primary;
        }
    }
}
.map-details{
    h5{
        margin-bottom: 0;
    }
    p{margin-bottom: 10px !important;
        span{font-weight: $semi-bold; color: $grey-primary; min-width: 130px; display: inline-block;}
    }
}
.gm-ui-hover-effect{margin-right: 6px !important; margin-top: 6px !important; outline: 0 !important;}
.delivery-date{
    .deliveryDays {display: flex;
        .btn{padding: 7px; height: auto; border-radius: 5px;
            outline: none !important;
                box-shadow: none !important;
            &:focus {
                outline: none !important;
                box-shadow: none !important;
              }
              &:active{background-color: $white;
              
            }
            &.btn-active{background-color: $blue; color: $white; border-color: $blue;}  
        span{font-weight: 600;}
        }

        .btn-success{
            background-color: $white;
            color: $grey-light;
            border-color: #C4C4C4;
        }
        .btn-check:checked + .btn-success{
            border-color: $blue; 
            background-color: $white !important;
            color: $grey-dark !important;
            outline: 0 !important;
            // &:active{
            //     background-color: $white !important;
            // }
            &:focus {
                outline: none !important;
                box-shadow: none !important;
              }
        }
    }
    
}
.modify-order-optionalfields{
    padding-left: 25px;
    padding-right: 0px;
    position: relative;
    &::before {
        content: "or (";
        @include font-16;
        position: absolute;
        top: 13px;
        left: 0;

    }
    &::after {
        content: ")";
        @include font-16;
        position: absolute;
        top: 13px;
        right: 0;

    }
    .react-dropdown-select-content{
        min-width: auto !important;
    }
}