@import "../styles/variables.scss";

.textarea-group {
    label {
        font-weight: $semi-bold;
    }
    .help-text{color: $grey-light;}
    .form-control {
        resize: none;
        min-height: 144px;
        @include common-font;

        &.error {
            border: 1px solid $red;
        }

        &.active {
            border: 1px solid $blue;
        }
    }
}