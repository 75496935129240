@import "../styles/variables.scss";

.navbar {
    .settings{
        color: $white;
        svg{
            path{
                fill:$white
            }
        }
    }
    .navbar-toggler {
        border: 0;

        .navbar-toggler-icon {
            background-image: url('../assets/Menu.svg') !important;

        }
    }
    .navbar-top {
        padding: 25px 0;
        background-color: $grey-primary;
        @media (max-width:700px) {
            padding: 15px 0;  
        }
        .navbar-brand{
            img{max-width: 170px;}
        }
        .nav-right {
            .navbar-toggler {

                @media (max-width:1450px) {
                    display: block;
                    box-shadow: none;
                }
            }
            &.header-search{
                .form-group{margin-bottom: 0;
                    .cust-ic-group{margin-bottom: 0;
                    input{
                        background-color: #29415B;
                        border: 0;
                        border-radius: 25px;
                        min-width: 325px;
                        height: 50px;
                        color: $white;
                    }
                }
                }
            }
        }
    }

    .navbar-text {
        color: $white !important;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        position: relative;

        &::after {
            content: "";
            display: inline-block;
            width: 4px;
            height: 4px;
            background: #C4C4C4;
            border-radius: 50%;
            position: absolute;
            right: 0;
        }

        @media (max-width:700px) {
            display: none;
        }

    }

    .profile-menu {
        margin-left: 50px;

        @media (max-width:1450px) {
            display: none;
        }

        .dropdown-toggle {
            padding: 0 !important;
            text-decoration: none;
            &:focus{box-shadow: none;}
            &:after {
                display: none;
            }

            .profile-dropdown {
                display: flex;
                align-items: center;
                color: $white;

                &:after {
                    content: "";
                    display: inline-block;
                    background: url('../assets/DownArrow.svg');
                    width: 24px;
                    height: 24px;
                    margin-left: 5px;
                }

            }
        }

        &.show {
            .profile-dropdown {
                &:after {
                    transform: rotate(180deg)
                }
            }
        }

        .dropdown-menu {
            border: 0;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24);
            padding-bottom: 15px;
            margin-top: 30px;
            left: auto;
            right: 0;

            .dropdown-item {
                color: $grey-light !important;
                padding-top: 10px;
                padding-bottom: 10px;
                @include common-font;
                &:focus,
                &:hover,
                &:active {
                    background-color: #e9ecef;
                    
                }
                svg {
                    margin-right: 12px;
                }
            }

        }
    }
}

.thumbnail-image {
    border-radius: 50%;
    margin-right: 14px;
    max-width: 50px;
    height: 50px;
}
.select_location__control{
    background: transparent !important;
    border: 0 !important;
    color: $white;
    &.select_location__control--is-focused{box-shadow: none;}
    .select_location__value-container{
        padding: 0;
    }
    .select_location__single-value{
        color: $white;
    }
    .select_location__indicators{
        display: none;
    }
}
.select_location__menu{
    min-width: 275px;
    border: 0;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24) !important;
margin-top: 27px !important;
    .select_location__option{
        color: $grey-light;
        &.select_location__option--is-selected{
            background: $blue-light;
            color: $white;
        }
    }
}