@import "../styles/variables.scss";

.cust-ic-group {
    .icon-wrap {
        position: absolute;
        left: 17px;
        top: 12px;
        width: 24px;
        height: 24px;

        svg {
            fill: $blue;
        }
    }

    .form-control {
        padding-left: 52px !important;

        &.error {
            border: 1px solid $red;
        }

        &.active {
            border: 1px solid $blue;
        }
    }
}

.content-nav-sub {
    .cust-ic-group {
        .form-control {
            border-color: $grey-border;
            padding-left: 52px !important;
            @include common-font;

            &.active {
                border-color: $blue;
            }

            &:focus {
                border-color: $blue;
            }
        }
    }
}
span {
    &.error {
        background: url(../assets/ErrorIcon.svg) 0 0 no-repeat;
        color: $red;
        @include common-font;
        margin-top: 14px;
        padding-left: 38px;
        display: block;
    }
}
