@import "../styles/variables.scss";

.cust-phone-group {
    label {
        @include common-font;
        margin-bottom: 12px;
        &:empty{
            display: none;
        }
        span{
            &.mandatory{
                color: $red;
            }
        }
    }

    .form-control {
        padding-left: 75px;

        &.error {
            border-color: $red;
        }

        &.active {
            border-color: $blue;
        }
    }

    .phone-wrap {
        .country-code {
            width: 45px;
            height: 50px;
            top: 1px;
            left: 1px;
            @include b-radius;
            background: #F3F3F3;
        }
    }
}
.ziplabel{
    label{
        @include common-font;
        margin-bottom: 12px;
    }
    .form-control {
        &.error {
            border-color: $red;
        }

        &.active {
            border-color: $blue;
        }
    }
}
span {
    &.error {
        background: url(../assets/ErrorIcon.svg) 0 0 no-repeat;
        color: $red;
        @include common-font;
        margin-top: 14px;
        padding-left: 38px;
        display: block;
    }
}
.basic-info{
    .country-code{
        height: 50px!important;
    }
    // .icon-wrap{
    //     top: 15px!important;
    // }
    .close-icon-wrap{
        top: 7px!important;
    }
}