@import "../styles/variables.scss";

.cust-ic-group {
    margin-bottom: 25px;

    .icon-wrap {
        position: absolute;
        left: 17px;
        top: 12px;
        width: 24px;
        height: 24px;

        svg {
            fill: $blue;
        }
    }

    .cutom-select {
        @include b-radius;
        @include common-font;
        border: 1px solid $grey-border;
        color: $grey-primary;
        height: 52px;
        padding-left: 52px !important;

        input {
            @include common-font;
            margin-left: 0;
            margin-right: -15px;
        }

        &.error {
            border-color: $red;
        }

        &.active {
            border-color: $blue;
        }

        &:focus {
            border-color: $blue;
            box-shadow: inherit;
        }

        &:focus-within {
            box-shadow: none;
        }

        &::-webkit-input-placeholder {
            color: $grey-light;
        }

        &:-ms-input-placeholder {
            color: $grey-light;
        }

        &::placeholder {
            color: $grey-light;
        }

        .react-dropdown-select-dropdown-handle {
            display: flex;
            background: url(../assets/UpArow.svg) center center no-repeat !important;
            width: 24px;
            height: 24px;
            margin-right: 10px;

            svg {
                display: none;
            }
        }

        .react-dropdown-select-dropdown {
            @include b-radius;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24);
            padding: 12px 0;
            border: 0;

            span {
                font-size: 15px;
                padding: 10px 20px;
                color: $grey-light;

                &:hover {
                    background: none;
                    color: $grey-primary;
                }

                &.react-dropdown-select-item-selected {
                    background: url(../assets/SelectTick.svg) right center no-repeat #F5F5F5;
                    color: $grey-light;
                }
            }
            
        }
    }

    .react-dropdown-select-option {
        @include font-14;
        background-color: $blue;

        .react-dropdown-select-option-remove {
            &:hover {
                color: $blue-socondary;
            }
        }
    }
}

.content-nav-sub {
    .cust-ic-group {
        .cutom-select {            
            &:focus-within {
                border-color: $blue;
            }
            .react-dropdown-select-content{margin-right: 15px;
            max-width: 80%;
            span{
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            }
        }

        .icon-wrap {
            path {
                fill: $blue !important;
            }
        }
    }
}

.modal-body {
    .cust-ic-group {
        .cutom-select {
            //height: 56px;
            border-color: $grey-border;
            @include common-font;
            padding-left: 52px !important;

            &:focus-within {
                border-color: $blue;
            }
        }

        .icon-wrap {
            top: 12px;
            left: 15px;
        }
    }

    .react-dropdown-select {
        .react-dropdown-select-dropdown {
            min-width: 100%;
            // top: 56px;
        } 
    }
}
.drop-min-100per{
    .react-dropdown-select-dropdown{
        min-width: 100%;
    }
}
.drop-max-350{
    .react-dropdown-select-dropdown{
        max-width: 350px !important;
    }
}
.drop-min-225{
    .react-dropdown-select-dropdown{
        min-width: 215px;
    }
}
.drop-min-180{
    .react-dropdown-select-dropdown{
        min-width: 180px;
    }
}
.drop-min-150{
    .react-dropdown-select-dropdown{
        min-width: 150px !important;
    }
}
.right-drop{
    .react-dropdown-select-dropdown{
        left: auto;
        right: 0;
    }
}

.prefix-vendor-miles{
    .react-dropdown-select-content{
        span{
            &::before{
                content: 'Show vendors within ';
            }
        }
    }
}
.no-icon-select {
    .cust-ic-group {
        margin-bottom: 0;

        .icon-wrap:empty {
            display: none;
        }

        .cutom-select {
            border-color: $grey-border;
            padding-left: 17px !important;
        }
    }
}