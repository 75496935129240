@import "../styles/variables.scss";

.pass-ic-group {
    .icon-wrap {
        position: absolute;
        left: 17px;
        top: 12px;
        width: 24px;
        height: 24px;

        svg {
            fill: $blue;
        }
    }

    .icon-pass {
        position: absolute;
        right: 10px;
        top: 4px;
        width: 44px;
        height: 44px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            fill: #97A2AD;
        }

        &:hover,
        &:active,
        &.active {
            svg {
                fill: $blue-primary;
            }
        }
    }

    .form-control {
        padding-left: 52px !important;

        &.error {
            border: 1px solid $red;
        }

        &.active {
            border: 1px solid $blue;
        }
    }
}