@import "../styles/variables.scss";
.product-img-container{
    &::after{
        content: '';
        position: absolute;
        width: 1px;height: 70%;
        background: $light-gray-border;
        top: -88px;
        margin-left: 50px;
    }
}
.product-details{
    .form-control{
        border-color: $light-gray-border;
        
    }
    .form-select{border-color: $light-gray-border;}
    p{
        @include font-16;
        line-height:30px ;
    }
    h3{
        font-size: 30px;
        font-weight: $semi-bold;
    }
    h5{
        margin-bottom: 0;
        color: $blue;
        font-weight:  $semi-bold;
        span{color: $grey-light; padding-left: 30px;}
    }
    .border-bottom{
        border-bottom-color: $light-gray-border !important;
    }
    .row{
        margin-bottom: 15px;
    .product-label{font-weight: $semi-bold; max-width: 225px;}

}
.productdetails-points{
    .form-control{
        max-width: 255px;
    }
    .w-mx-172{
        max-width: 172px;
    }

}
.w-175{width: 175px;}
.w-200{width: 200px;}
.mt-10px{margin-top: 10px;}
.mt-14px{margin-top: 14px;}
}
