@import "../styles/variables.scss";

.role-demo{
    font-size: 20px;
    min-width: 245px;
@include b-radius;
img{min-width: 25px; max-width: 25px;}
}
.permission-options{min-width: 200px; max-width: 285px !important;}
.permission-options-events{min-width: 275px;}
.role-icon-options{
    .btn-primary{
        background-color: $light-gray-border !important;
        width: 52px;
        padding: 0;
        border-radius: 4px !important;
        margin-right: 5px !important;
    }
}
.selcted-role-icon{
    display: flex;
    img{
        min-width: 25px;
    max-height: 25px;
    }
}
.icon-blue{
    svg{
        margin-right: 10px;
        path{fill: $blue-primary}
    }
}