@import "../styles/variables.scss";

.rolelist-border{
    border: 1px solid #EFEFEF;
    border-radius: 4px;
    min-height: 65px;
    .role-icon{
        img{min-width: 25px; max-width: 25px;} 
    }
    a{
        display: block;
        padding: 20px;
        border-left: 1px solid #E0E0E0;
    }
}