@import "../styles/variables.scss";
label{
    margin-bottom: 12px;    
}

.cust-location-group {    
    &.error {
        .form-control {
            border-color: $red !important;
        }
    }

    &.active {
        .form-control {
            border-color: $blue;
        }

        .icon-wrap {
            svg {
                fill: $blue !important;
            }
        }
    }

    &:hover,
    &:focus,
    &:active {
        .icon-wrap {
            svg {
                fill: $blue !important;
            }
        }
    }

    .icon-wrap {
        position: absolute;
        left: 17px;
        top: 12px;
        width: 24px;
        height: 24px;

        svg {
            fill: $grey-light;
        }
    }

    .close-icon-wrap {
        position: absolute;
        right: 4px;
        top: 4px;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            fill: $grey-light !important;

            &:hover {
                fill: $red !important;
                cursor: pointer;
            }
        }
    }

    .form-control {
        padding-left: 52px;
        padding-right: 55px;
        white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    }
}

span {
    &.error {
        background: url(../assets/ErrorIcon.svg) 0 0 no-repeat;
        color: $red;
        @include common-font;
        margin-top: 14px;
        padding-left: 38px;
        display: block;
    }
}
.sm-error-td{
    span{
        &.error{
    font-size: 13px;
    font-weight: normal;
    background-size: 17px;
    padding-left: 22px;
    max-width: 200px;
    margin-top: 5px;
    
}
}
}