@import "../styles/variables.scss";

.onboard-wrap {
    min-height: 100vh;

    .logo-wrap {
        background: rgb(19, 123, 174);
        background: linear-gradient(161.05deg, #B6D9EF 0%, #B4D8EF 0.01%, #137BAE 100%);
        position: relative;
        padding: 75px 0;

        @media (max-width:576px) {
            padding: 30px;
        }

        .pattern {
            width: 100%;
            height: 233px;
            background: url(../assets/OnboardPattern.svg) 0 0 no-repeat;
            background-size: cover;
            position: absolute;
            bottom: 0;
            left: 0;
            opacity: .5;
        }
    }

    .form-container {
        @media (max-width:576px) {
            padding: 30px;
        }

        background: $white;

        .form-wrap {
            max-width: 500px;
            min-width: 500px;

            @media (max-width:1200px) {
                max-width: 90%;
                min-width: 90%;
            }

            @media (max-width:992px) {
                max-width: 80%;
                min-width: 80%;
            }

            @media (max-width:576px) {
                max-width: 100%;
                min-width: 100%;
            }

            h1 {
                @include onboard-head;
                color: $grey-primary;
                font-weight: $regular;
                margin-bottom: 38px;
            }

            h3 {
                @include font-18;
                font-weight: $regular;
                margin-top: 15px;
                color: $black;
            }

            h4 {
                font-size: 16px;
                color: $blue-primary;
                text-transform: uppercase;
                font-weight: $semi-bold;
                margin: 40px 0 0 0;
                border-bottom: #EFEFEF solid 1px;
                padding-bottom: 10px;
                margin-bottom: 12px;
            }

            .loc-not-found {
                margin-bottom: 80px;

                svg {
                    fill: $grey-light;
                    margin-right: 15px;
                }
            }

            .locatoin-wrap {
                min-height: 200px;
            }

            .welcome-user {
                color: $grey-light;
                margin-bottom: 10px;
            }

            .restaurant {
                padding-top: 30px;

                h2 {
                    font-size: 24px;
                    font-weight: $semi-bold;
                    margin-bottom: 20px;
                }
            }
        }


        .form-wrap {
            &.vendor {
                width: 85%;
                max-width: 85%;
                min-width: 85%;

                @media (max-width:1200px) {
                    max-width: 100%;
                    min-width: 100%;
                    padding: 0 30px;
                }
            }
        }

        .button-wrap {
            margin-top: 40px;
            display: flex;
            justify-content: flex-end;

            @media (max-width:576px) {
                flex-direction: column;
            }

            .btn-light {
                padding: 0 45px;

                @media (max-width:576px) {
                    &:first-child {
                        margin-bottom: 20px;
                    }
                }
            }
        }

    }

    .form-container {
        padding: 75px 30px;

        &.stepper {
            padding-top: 170px;
        }
    }

    .otp-verificatoin {
        .form-wrap {
            .otp-info {
                margin: 100px 0 35px 0;
                color: $grey-light;

                span {
                    color: $blue;
                }
            }
        }
    }

    .login-actions {
        margin-bottom: 40px;

        a {
            text-decoration: none;
            font-size: 14px;
            font-weight: $semi-bold;

            &:hover {
                color: $blue;
                text-decoration: underline;
            }
        }
    }

    .signup-agree {
        @include font-14;
        margin-top: -10px;
        color: $grey-light;
        margin-bottom: 40px;

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .vendor {
        .delivery-days {
            margin-bottom: 25px;

            label {
                display: block;
                @include common-font;
                margin-bottom: 16px;
                font-weight: $semi-bold;
            }

            .btn-group {
                width: 100%;

                @media (max-width:576px) {
                    display: flex;
                    flex-direction: column;
                }

                &.error {
                    label {
                        border: 1px solid $red;
                    }
                }


                label {
                    background: none;
                    border: 1px solid #E0E0E0;
                    height: 48px;
                    color: $grey-light;
                    @include common-font;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 0;

                    &:last-child {
                        border-radius: 0 4px 4px 0;
                    }

                    &:nth-child(2) {
                        border-radius: 4px 0 0 4px;
                    }

                    &:hover {
                        background: #4296C1;
                        color: $white !important;
                    }

                    &:focus,
                    &:active {
                        border: 1px solid #E0E0E0 !important;
                        box-shadow: none !important;
                        outline: none !important;
                        box-shadow: none !important;
                    }
                }
            }
        }

    }

    .RFS-StepperContainer {
        padding: 0 !important;
    }

    .stepper-wrap {
        top: 65px;
        width: 65%;

        .from-stepper {
            .RFS-StepButton {
                width: 40px;
                height: 40px;
                background-color: $white;
                border: 2px solid #c7e0ed;
                cursor: default;

                .RFS-StepButtonContent {
                    font-size: 20px;
                    color: #c7e0ed;
                    font-weight: $semi-bold;
                }

                &.active {
                    border: 2px solid #8ec0da;
                    background-color: #8ec0da;

                    .RFS-StepButtonContent {
                        color: $white;
                    }
                }

                &.completed {
                    border: 2px solid #4296c1;
                    background-color: #4296c1;

                    .RFS-StepButtonContent {
                        color: $white;
                    }
                }
            }

            .RFS-ConnectorContainer {
                top: calc(1em - -3px);
                left: calc(-50% + 2em - 12px);
                right: calc(50% + 2em - 12px);

                .RFS-Connector {
                    border-top-width: 3px;
                    border-color: #d9eaf3;

                    &.completed {
                        border: 2px solid #4296c1;
                        background-color: #4296c1;
                    }

                    &.active {
                        border: 2px solid #8ec0da;
                        background-color: #8ec0da;
                    }
                }
            }
        }
    }
}