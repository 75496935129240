//Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
$primary-font: 'Open Sans',
sans-serif;

//Primary Colors
$blue: #4296C1;
$blue-light:#1079AD;
$blue-primary:#4296C1;
$blue-btn:#4296C1;
$grey-bg:#F2F2F2;
$grey-primary:#1F3349;
$grey-light:#63707E;
$grey-dark: #4f545a;
$grey-border:#D3D3D3;
$red:#D50000;
$red-light:#FFF1F1;
$white:#fff;
$btn-prm-hover:#138BC7;
$table-grey: #f9f9f9;
$blue-socondary: #E7EEF8;
$light-gray-border: #efefef;
$icon-bg:#ebebeb;
$black: #000000;
$green: #42B72A;
$dark-green: #31981c;
$light-green:#1EB2A6;
$dark-Red: #6E0A0A;
$dark-Red-Hover: #4a0202;
$orange: #FF9B26;


//Font Weights
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold:800;

//Mixins
@mixin b-radius() {
    border-radius: 4px;
}

@mixin btn-radius() {
    border-radius: 100px;
}

@mixin modal-radius() {
    border-radius: 20px;
}

@mixin shadow() {
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.1);
}

@mixin common-font {
    font-size: 16px;
}

@mixin onboard-head {
    font-size: 28px;
}

@mixin font-18 {
    font-size: 18px;
}

@mixin h4-font {
    font-size: 20px;
}

@mixin font-14 {
    font-size: 14px;
}

@mixin font-16 {
    font-size: 16px;
}

@mixin modal-head {
    font-size: 20px;
    line-height: 32px;
    font-weight: $semi-bold;
    color: $grey-primary;
}