@import "../styles/variables.scss";

.footer {
    background: $grey-primary;
    padding: 15px 0;
    color: $white;

    a {
        color: $white !important;
    }

    .footer-left {
        @media (max-width:700px) {
            display: block !important;
            text-align: center;
        }

        .navbar-brand {
            img{
                max-width: 64px;
                margin-right: 50px;
            }
            @media (max-width:700px) {
                margin-right: 0;
            }

            img {
                @media (max-width:700px) {
                    max-width: 50px;
                }
            }
        }
    }

    .footer-right {
        span {
            margin-left: 35px;

            @media (max-width:700px) {
                margin: 0 15px;
            }

            &.online {
                &::before {
                    width: 24px;
                    height: 24px;
                    content: '';
                    display: inline-block;
                    background-color: #42B72A;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }

            svg {
                margin-right: 10px;
            }
        }

        @media (max-width:700px) {
            justify-content: center;
        }
    }
}