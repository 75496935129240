@import '../styles/variables.scss';

.main-menu {
  background: $white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  padding: 0;
  @media (max-width: 1450px) {
    display: none !important;
  }
  .nav-link {
    display: flex;
    align-items: center;
    padding: 16px 11px !important;
    color: $grey-light !important;
    &:hover {
      color: $blue-light !important;
      svg {
        path {
          fill: $blue-light;
        }
      }
    }
    &.active {
      border-bottom: $blue-light 2px solid;
      color: $blue-light !important;
      padding-bottom: 14px !important;
      svg {
        path {
          fill: $blue-light;
        }
      }
      .profile-dropdown{
        color: $blue-light !important;
      }
    }
    svg {
      margin-right: 10px;
      @media (max-width: 1450px) {
        max-width: 22px;
        min-width: 22px;
      }
    }
    label {
      cursor: pointer;
    }
    &:last-child {
      // padding-right: 0 !important;
      &.active {
        // border: 0;
        //padding-bottom: 27px !important;
      }
      svg {
        //margin-right: 0px;

        @media (max-width: 1450px) {
          margin-right: 10px;
        }
      }
    }
    .indicator-active {
      @media (max-width: 1450px) {
        margin-right: 10px;
      }
      svg {
        margin-right: 0;
        @media (max-width: 1450px) {
          max-width: 19px;
          min-width: 19px;
          max-height: 21px;
        }
      }
    }
    .menu-responsive {
      @media (min-width: 1450px) {
        display: none;
      }
    }
  }
  .nav-link-disabled {
    display: flex;
    align-items: center;
    padding: 16px 25px !important;
    color: $grey-light !important;
    &:hover {
      color: $blue-light !important;
      svg {
        path {
          fill: $blue-light;
        }
      }
    }
    svg {
      margin-right: 10px;
      @media (max-width: 1450px) {
        max-width: 22px;
        min-width: 22px;
      }
    }
    label {
      cursor: pointer;
    }
    &:last-child {
      padding-right: 0 !important;
      &.active {
        border: 0;
        //padding-bottom: 27px !important;
      }
      svg {
        //margin-right: 0px;

        @media (max-width: 1450px) {
          margin-right: 10px;
        }
      }
    }
    .indicator-active {
      @media (max-width: 1450px) {
        margin-right: 10px;
      }
      svg {
        margin-right: 0;
        @media (max-width: 1450px) {
          max-width: 19px;
          min-width: 19px;
          max-height: 21px;
        }
      }
    }
    .menu-responsive {
      @media (min-width: 1450px) {
        display: none;
      }
    }
  }
  .restaurant-menu {
    .rest-menu-subnav {
      @media (min-width: 1450px) {
        display: flex;
      }
    }
  }
  .main-menu-drop {
   .dropdown-toggle {

        text-decoration: none;
        &:focus{box-shadow: none;}
        &:after {
            display: none;
        }

        .profile-dropdown {
            display: flex;
            align-items: center;
            color: $grey-light;
            &:hover{
              color: $blue-light;
            }

            &:after {
                content: "";
                display: inline-block;
                background: url('../assets/DownBlue.svg') no-repeat center;
                width: 24px;
                height: 24px;
                margin-left: 5px;
            }

        }
    }

    &.show {
        .profile-dropdown {
            &:after {
                transform: rotate(180deg)
            }
        }
    }

    .dropdown-menu {
        border: 0;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24);
        padding-bottom: 15px;
        left: 0;
        right: auto;
        position: absolute;
        width: 205px;

        .dropdown-item {
            color: $grey-light !important;
            padding-top: 10px;
            padding-bottom: 10px;
            @include common-font;
            &:focus,
            &:hover,
            &:active {
                background-color: #e9ecef;
                
            }
            svg {
                margin-right: 12px;
            }
            &.active{background-color: #e9ecef;} 
        }

    }
    &.active {
      border-bottom: $blue-light 2px solid;
      
      svg {
        path {
          fill: $blue-light;
        }
      }
      .nav-link{
        padding-bottom: 14px !important;
      }
      .profile-dropdown{
        color: $blue-light !important;
      }
    }
}
}
.offcanvas {
  .offcanvas-body {
    padding-left: 0;
    .main-menu {
      box-shadow: none !important;
      @media (max-width: 1450px) {
        display: block !important;
      }
      .menu-list {
        display: block !important;
        .nav-link {
          padding: 15px !important;
          &.active {
            border-bottom: 0;
            border-left: $blue-light 5px solid;
          }
        }
        &.content-nav {
          max-width: 100%;
        }
      }
    }
  }
  .offcanvas-header {
    color: $white;
    background: $grey-primary;
    @media (max-width: 700px) {
      padding-bottom: 40px;
    }

    .navbar-text {
      @include font-14;
      font-weight: $regular;
      padding: 0 15px;
      position: relative;
      &::after {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        background: #c4c4c4;
        border-radius: 50%;
        position: absolute;
        right: -2px;
        top: 9px;
      }
      &:last-child::after {
        display: none;
      }
      @media (min-width: 701px) {
        display: none;
      }
    }
    .mob-profile-details {
      .navbar {
        padding: 0;
        .nav-link {
          padding: 5px 15px;
          color: $white;
          font-weight: $regular;
          color: $blue-primary;
          @include font-16;
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
    .btn-close {
      background-image: url(../assets/Close.svg) !important;
    }
  }
}
.cutofftime {
  display: flex;
  align-items: center;
  .btn {
    svg {
      margin-right: 0;
      path {
        fill: $grey-light !important;
      }
    }
  }
  .dropdown-menu {
    position: absolute !important;
    margin-top: 0 !important;
    min-width: 300px;
    padding: 0 !important;
    .table {
      @include font-14;
      margin-bottom: 0;
      tr {
        height: 50px;
        td {
          &:first-child {
            padding-left: 15px;
          }

          &:last-child {
            padding-right: 15px;
          }
        }
        th {
          &:first-child {
            padding-left: 15px;
          }

          &:last-child {
            padding-right: 15px;
          }
        }
      }
    }
    .table-responsive {
      min-height: auto;
    }
    .cutoff {
      display: flex;
      align-items: center;
      &::before {
        content: '';
        background-color: #c4c4c4;
        width: 15px;
        height: 15px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 12px;
      }
      &.low {
        &::before {
          background-color: $green;
        }
      }
      &.medium {
        &::before {
          background-color: $orange;
        }
      }
      &.high {
        &::before {
          background-color: $red;
        }
      }
    }
  }
}
