@import './variables.scss';

.settings-wrap {

  h2 {
      font-size: 24px;
      height: 100px;
      padding: 35px;
      font-weight: $semi-bold;
  }

  .left-wrap {
      min-width: 296px;
      background: #F9FBFC;

      @media (max-width:1200px) {
          display: none;
      }

      h2 {
          border-bottom: 1px solid #E6E6E6;
          text-align: center;
          color: $blue-primary;
      }

  }

  .right-wrap {
      .header {
          padding-right: 35px;
          // border-bottom: 1px solid #EFEFEF;

          @media (max-width:576px) {
              flex-direction: column;
              padding-bottom: 20px;
          }

          h2 {
              display: block;
              height: 100px;
          }

          .navbar {
              @media (max-width:576px) {
                  position: absolute;
                  top: -95px;
                  right: 0;
              }
          }

          .navbar-toggler {
              background: $grey-primary;
              padding: 10px 15px;
              margin: 0 0 0 15px !important;

              @media (min-width:1200px) {
                  display: none;
              }

              .navbar-toggler-icon {
                  width: 24px !important;
              }
          }

          .button-wrap {
              align-items: center;

              @media (max-width:576px) {
                  flex-direction: column;
                  position: relative;
              }

              button {
                  margin-left: 30px;

                  @media (max-width:576px) {
                      margin-left: 0;
                      margin-top: 15px;
                  }
              }
          }
      }

      .settings-body {
          padding: 35px;
          .company-logo-view{
              img{
                  max-width: 175px;
              }
              //border-bottom: $grey-border solid 1px;
              margin-bottom: 30px;
          }
          .btn-outline-secondary{
              svg{
                  max-width: 25px;
              }
          }
          .delivery-routes {

              th,
              td {
                  padding-top: 25px;
                  padding-bottom: 25px;
              }

              th {
                  &.extra-delivery {
                      width: 198px;
                  }
              }

              .delete {
                  a {
                      color: $red;
                  }
              }

              .cust-label-group {

                  .label-wrap,
                  label {
                      display: none;
                  }

                  .cutom-select {
                      height: 52px;
                  }
              }

              .form-group {
                  margin-bottom: 0;

                  .cust-label-group {
                      .form-control {
                          width: 100px;
                      }
                  }
              }
          }

          &.delivery-trucks {
              .form-control {
                  height: 52px;
              }

              .buttons-wrap {
                  button {
                      margin-right: 0;
                      padding-left: 50px;
                      padding-right: 50px;
                  }
              }

              p {
                  &.not-added {
                      @include h4-font;
                      color: $grey-primary;
                      color: $grey-light;
                  }
              }
          }

          .nav-tabs {
              .nav-item {
                  padding-bottom: 10px;

                  .nav-link {
                      color: $grey-light;
                      margin-bottom: 0;
                      border: none !important;
                      padding-left: 30px;
                      padding-right: 30px;

                      &.active {
                          color: $blue-primary;
                          font-weight: $semi-bold;
                          border: none;
                          position: relative;

                          &::after {
                              content: " ";
                              height: 4px;
                              width: 50px;
                              background: $blue-primary;
                              position: absolute;
                              bottom: -10px;
                              right: calc(100% - 60%);
                              border-radius: 2px 0 2px 0;
                          }
                      }

                      &:hover,
                      &:active,
                      &:focus,
                      &:focus-visible {
                          border: none !important;
                          margin: 0;
                      }
                  }

                  button {
                      &:focus-visible {
                          outline: none !important;
                      }
                  }
              }
          }

          .tab-content {
              padding: 30px 20px;

              .color-code {
                  width: 30px;
                  height: 30px;
              }

              .settings-badge {
                  display: flex;
                  flex-wrap: wrap;

                  span {
                      background: #F7FFFE;
                      border-radius: 22px;
                      height: 44px;
                      min-width: 95px;
                      color: $light-green;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin: 0 30px 30px 0;
                      padding: 0 26px;
                  }
              }
          }

          .profile-header {
              background: $grey-primary;
              padding: 30px 35px;

              @media (max-width:576px) {
                  flex-direction: column;
              }
              .add {
                  width: 30px;
                  height: 30px;
                  border-radius: 15px;
                  background: $white;
                  cursor: pointer;
                  bottom: 0;
                  right: 0;
                  transition: 0.3s;
                  @include shadow;

                  svg {
                      width: 18px;
                      fill: $blue-btn;
                      transition: 0.3s;
                  }

                  &:hover,
                  &:focus,
                  &:active {
                      background: $blue-btn;

                      svg {
                          fill: $white;
                      }
                  }
              }
              .profile-photo {
                  @media (max-width:576px) {
                      display: flex;
                      justify-content: center;
                  }

                  .image-wrap {
                      width: 112px;
                      height: 112px;

                      img {
                          max-width: 100%;
                          border-radius: 56px;
                          height: 100%;
                      }

                      
                  }
              }

              .profile-details {
                  padding-left: 24px;

                  @media (max-width:576px) {
                      padding-left: 0;
                      text-align: center;
                      padding-top: 20px;
                      line-height: 38px;
                  }

                  span {
                      @include h4-font;

                      &.name {
                          font-size: 26px;
                          color: $white;
                          font-weight: $semi-bold;
                      }

                      &.role {
                          color: $grey-light;
                      }

                      &.status {
                          @include font-14;
                          color: #2F6F8F;
                      }
                  }

                  .phone {
                      span {
                          &.number {
                              color: $blue-primary;
                          }

                          &.verify {
                              width: 95px;
                              height: 30px;
                              border: 1px solid $blue-socondary;
                              display: block;
                              color: #F2F4F7;
                              text-align: center;
                              @include common-font;
                              border-radius: 100px;
                              cursor: pointer;
                              transition: 0.3s;
                              margin-left: 10px;

                              &:hover {
                                  background: $blue-socondary;
                                  color: $grey-primary;
                              }
                          }

                          &.verified {
                              width: 115px;
                              height: 30px;
                              border: 1px solid $blue-socondary;
                              display: block;
                              color: #F2F4F7;
                              text-align: center;
                              @include common-font;
                              border-radius: 100px;
                              margin-left: 10px;

                              svg {
                                  fill: $white;
                                  margin-right: 5px;
                              }
                          }
                      }
                  }
              }
              .btn-outline-primary{
                  color: #fff;
                  &:hover{
                      color: $blue-primary;
                  }
              }
          }

          .profile-body {
              .nav-tabs {
                  border: 0;
                  box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.12);
                  padding-top: 10px;
              }

              .profile-inner {
                  padding: 35px;

                  h1 {
                      font-size: 24px;
                      color: $grey-primary;
                      margin-bottom: 35px;
                  }

                  .existing-mail {
                      color: $grey-light;
                      margin-bottom: 24px;

                      span {
                          color: $blue-primary;
                          padding-left: 12px;
                      }
                  }

                  .form-check-label {
                      color: $grey-primary;
                  }

                  .check-wrap {
                      height: inherit;
                  }

                  .checkbox-group {
                      margin-right: 20px;
                  }

                  span {
                      &.notify-label {
                          @media (max-width:576px) {
                              padding-bottom: 15px;
                              display: block;
                              font-weight: $semi-bold;
                          }
                      }
                  }
              }
          }

          &.cutoff-time {
              h4 {
                  @include h4-font;
                  color: $grey-light;
                  margin-bottom: 35px;
              }

              .cust-label-group {
                  .cutom-select {
                      height: 52px;
                  }
              }

              .info {
                  background: $icon-bg;
                  border-radius: 10px;
                  padding: 17px;
                  color: $grey-light;
                  line-height: 25px;
              }

              h3 {
                  font-size: 24px;
                  padding: 35px 0 10px;
                  font-weight: $semi-bold;
              }

              .form-control {
                  height: 52px
              }

              .visibility {
                  .form-group {
                      margin-bottom: 0;

                      .check-wrap {
                          height: 52px
                      }
                  }

                  .understand {
                      padding: 26px 0 0;

                      span {
                          font-style: italic
                      }
                  }
              }

              .general-view {
                  margin-bottom: 15px;

                  label {
                      font-weight: $semi-bold;
                  }
                  .col-lg-4{max-width: 147px}
              }
              p{
                  &.prefix-order{
                      @include font-14;
                      color: $grey-light;
                  }
              }
          }

          .account-dtls {
              @include b-radius;
              border: 1px solid #D7D7D7;

              .top-wrap {
                  padding: 10px 15px;
                  align-items: center;
                  .card-type{
                      max-width: 65px;
                      img{
                          max-width: 100%;
                          max-height: 35px;
                      }
                  }
                  .card-dtls {
                      //padding-left: 15px;
                      @include common-font;
                      width: 100%;
                      p {
                          margin: 0;
                      }
                      span{
                          display: block;
                          background: #FBFBFB;
                          padding: 5px 10px;
                          border-radius: 3px;
                          margin-bottom: 15px;
                      }
                  }
              }

              .bottom-wrap {
                  border-top: 1px solid #D7D7D7;
                  padding: 10px 15px;
                   p{margin-bottom: 0;}   
                  a {
                      @include common-font;
                  }
              }
          }

          &.vendor-location {
              h4 {
                  font-weight: $semi-bold;
                  text-transform: uppercase;
                  color: $blue-primary;
                  @include common-font;
                  border-bottom: $light-gray-border 1px solid;
                  padding-bottom: 10px;
                  margin-top: 30px;
              }

          }

          .invoicing {
              align-items: center;

              p {
                  margin-bottom: 0;
                  padding-right: 20px;

                  &.days {
                      padding-left: 20px;
                  }
              }

              .form-control {
                  width: 165px;
              }

              .form-check {
                  margin: 0 20px 0 0;
                  display: flex;
                  align-items: center;

                  // label {
                  //     margin-bottom: 0;
                  //     margin-left: 8px;
                  // }
              }

              .percentage {

                  .perc {
                      right: 13px;
                      top: 11px;
                      @include h4-font;
                      font-weight: $semi-bold;
                      color: $grey-light;
                  }

                  .form-control {
                      padding-right: 30px;
                  }
              }

              textarea {
                  width: 100% !important;
                  @include b-radius;
                  border: 1px solid $grey-border;
                  resize: none;
                  min-height: 110px;
              }

              &.tax-type {
                  @media (max-width:992px) {
                      flex-direction: column;
                      align-items: flex-start;
                  }

                  .form-check {
                      @media (max-width:992px) {
                          margin-top: 20px;
                      }
                  }

                  .percentage {
                      @media (max-width:992px) {
                          margin-top: 20px;
                      }
                  }

              }

              .fuel {
                  @media (max-width:992px) {
                      justify-content: inherit !important;
                  }

                  p {
                      @media (max-width:992px) {
                          padding-right: 15px !important;
                      }
                  }
              }
          }

          .delivery-routes-table {
              .table-responsive {
                  @media (min-width:1200px) {
                      overflow-x: inherit;
                  }
              }
          }
          .btn-primary{min-width: 200px;}
      }

  }
}

.offcanvas-body {
  .menu-wrap {
      padding: 0px;
  }
}

.menu-wrap {
  display: flex;
  padding: 30px;

  a {
      display: flex;
      color: $grey-light;
      line-height: 22px;
      padding: 20px;
      margin-right: 10px;
      border-radius: 10px;
      transition: 0.3s;

      svg {
          fill: $grey-light;
          transition: 0.3s;
      }

      &:hover,
      &:focus,
      &:active,
      &.current {
          background: $blue-socondary;
          color: $blue-primary;

          svg {
              fill: $blue-primary;
          }
      }

      &.current {
          font-weight: $semi-bold;
      }
  }
}

.view-map {
  .remove-route {
      background: #F9FBFC;
      padding: 15px;
      margin-bottom: 5px;
      transition: 0.3s;

      &:hover {
          background: $blue-socondary;
      }

      &.active {
          background: $blue-socondary;
      }

      &:last-child {
          margin-bottom: 0;
      }

      .header {
          display: flex;
          justify-content: space-between;            

          h4 {
              @include font-14;
              font-weight: $semi-bold;
              color: #1F3349;
              margin: 0;
          }

          span {    
              @include font-14;            
              color: $red;
              text-decoration: underline;
              cursor: pointer;
          }
      }

      p {
          @include font-14;
          color: $grey-light;
          margin-bottom: 5px;
      }
  }
}

.add-payment-method {
  label {
      font-weight: $semi-bold;
  }

  span {
      &.icon-wrap {
          margin-bottom: 35px;
          display: block;

          svg {
              path {
                  fill: $grey-light;
              }
          }
      }
  }

  .checkbox-group {
      .check-wrap {
          height: inherit;
      }
  }

  .cust-label-group {
      .form-control {
          height: 52px;
      }
  }
}

.gm-style-iw-c {
  padding: 0 !important;

  .route-viwer {
      .header {
          padding: 10px 17px;
          border-bottom: .5px $light-gray-border solid;
          @include common-font;
          font-weight: $semi-bold;
          color: $blue-primary;
      }

      .viewer-body {
          padding: 10px 17px;

          p {
              @include font-14;
              color: $grey-light;
              margin-bottom: 10px;
          }
      }
  }

  button {
      top: 2px !important;
      right: 0 !important;
  }
}
.download{
  background: #F2F4F7!important;
  width: 68px!important;
  height: 68px!important;
  min-width: inherit!important;      
  border-radius: 10px!important;  
  padding: 0!important;
  svg{
      margin: 0!important;
      path{
          fill: $blue-primary!important;
      }
  }
  &:hover{
      svg{            
          path{
              fill: $white!important;
          }
      } 
  }
}
.info{
  svg{
      path{
          fill: $grey-light;
      }
  }
}
.premium-version{
  label{
      color: $grey-primary;
      font-weight: $semi-bold;
  }
  span{color: $grey-light;}
  p{
      margin-bottom: 20px !important;
  }
  .card-dtls{
      p{
          margin: 5px 0 !important;
      }
  }
}
