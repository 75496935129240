@import '../styles/variables.scss';

.checkbox-group {
  .label {
    @include common-font;
    margin-bottom: 12px;
    font-weight: $semi-bold;
    &:empty {
      display: none !important;
    }
  }
  .check-wrap {
    height: 52px;
    .form-check-input {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      position: inherit;
      background-color: $white;
      border: 2px solid $grey-light;
      -webkit-appearance: none;
      appearance: none;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
      cursor: pointer;
      &:checked {
        background-color: $blue;
        border-color: $blue !important;
      }
      label {
        padding-left: 10px !important;
        display: block;
      }
    }
    .form-check-label {
      font-weight: $regular;
      margin-bottom: 0;
    }
  }
}

.form-check {
  display: flex !important;
  [type='radio'] {
    border-radius: 50% !important;

    &:checked[type='radio'] {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
    }
  }
  [type='checkbox'] {
    border-radius: 4px;

    &:checked[type='checkbox'] {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E");
    }
  }
}
.permission-settings {
  .form-group {
    margin-bottom: 20px;

    .check-wrap {
      height: auto;
    }
  }
  &.New {
    .form-check-label {
      color: $blue-primary !important;
      font-weight: $semi-bold;
    }
    ul {
        padding-left: 0;
        li {
          padding-left: 32px;
          list-style: none;
          .form-check-label {
            color: $grey-light !important;
            font-weight: $regular;
            font-style: italic;
          }
        }
      }
  }
}
.content-nav {
  .content-nav-sub {
    .form-check-input {
      border-color: #e0e0e0;
      border-radius: 2px;
      margin-top: 2px;
    }
    .permission-settings {
      .form-check-input {
        border-color: $grey-light;
        border-radius: 2px;
      }
      &.inline-checkbox {
        .form-group {
          margin-right: 30px;
          display: inline-block;
          label {
            margin-bottom: 0;
          }
        }
      }
    }
    .table {
      td {
        &.checkboxtd {
          .form-group {
            margin-bottom: 0;
            height: 20px;
            .check-wrap {
              height: auto;
            }
            .form-check-input {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
.order-form {
  .form-group {
    margin-bottom: 0;
    .checkbox-group {
      .check-wrap {
        height: auto;
      }
    }
  }
}

.modal {
  .form-check-input {
    border-color: #e0e0e0;
    border-radius: 2px;
    margin-top: 2px;
  }
}
.check-with-textbox {
  .form-group {
    margin-bottom: 0;
    .checkbox-group {
      .check-wrap {
        height: auto;
        .form-check {
          margin-bottom: 0;
        }
      }
    }
  }
}
.no-margin-formgroup {
  .form-group {
    margin-bottom: 0;
  }
}
.checkall {
  .form-check-input {
    margin-right: 5px !important;
  }
}
