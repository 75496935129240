@import "../styles/variables.scss";

.cust-label-group {
    label {
        @include common-font;
        margin-bottom: 12px;

        span {
            &.mandatory{
                color: $red;
            }            
        }

            &:empty {
                display: none;
            }
    }

    .form-control {

        &.error {
            border-color: $red;
        }

        &.active {
            border-color: $blue;
        }
    }
}

span {
    &.error {
        background: url(../assets/ErrorIcon.svg) 0 0 no-repeat;
        color: $red;
        @include common-font;
        margin-top: 14px;
        padding-left: 38px;
        display: block;
    }
}

.content-nav-sub {

    .basic-info,
    .pricing {
        .cust-label-group {
            label {
                font-weight: $semi-bold;
            }
        }

    }
}

.no-label-input {
    .form-group {
        margin-bottom: 0 !important;

        label {
            &:empty {
                display: none;
            }
        }

        .form-control {
            [attribute=value] {
                color: red;
            }
        }
        .icon-wrap{
            top: 13px !important;
            left: 13px;
        }
    }
}

.form-sm {
    .cust-label-group{
            label:empty{
                display: none;
            }
    }
    .form-control {
        height: auto !important;
    }
    .cutom-select{
        height: 40px !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
        .react-dropdown-select-dropdown-handle{
            margin-right: 2px;
        }
        span{
            font-size: 15px;
        }
    }
}
.form-md {
    .cust-label-group{
            label:empty{
                display: none;
            }
    }
    .form-control {
        height: 42px !important;
    }
    .cutom-select{
        height: 45px !important;
        padding-left: 12px;
        .react-dropdown-select-dropdown-handle{
            margin-right: 2px;
        }
    }
}

.txt-center {
    .form-control {
        text-align: center;
    }
}

.doller-prefix {
    position: relative;

    &::before {
        content: "$";
        position: absolute;
        top: 14.5px;
        margin-left: 15px;
        font-size: 16px;
    }

    .form-control {
        padding-left: 25px !important;
    }

    &.form-sm {
        &::before {
            top: 6.9px;
        }
    }
}

.doller-prefix-outer{
    position: relative;

    &::before {
        content: "$";
        position: absolute;
        top: 14.5px;
        margin-left: -15px;
        font-size: 16px;
    }

    &.form-sm {
        &::before {
            top: 6.9px;
        }
    }
}
.lbs-prefix {
    position: relative;

    & label {
        
        position: absolute;
        top: 14.5px;
        margin-left: 0px;
        left: 105px;
    }

    .form-control {
        //padding-right: 35px !important;
    }

    &.form-sm {
        & label {
            top: 5px;
        }
    }
}
.prefix-before{ 
    margin-bottom: 0;   
    padding-top: 7px;
    padding-right: 5px;}
    .prefix-after{ 
        margin-bottom: 0;   
        padding-top: 7px;
        padding-left: 5px;}
.input-file {
    .form-control {
        line-height: 40px;
    }
}
span{
    &.inputlabel{
        @include common-font;
        margin-bottom: 12px;
        display: inline-block;
        font-weight: $semi-bold; 
    }
}